<template>
  <div v-loading.sync="listLoading" style="padding: 10px">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div style="display: flex; align-items: center">
        <h3 style="margin-right: 10px">当前收藏</h3>
        <h3 style="color: rgb(37, 115, 217)">{{ detail.totalColNum }}</h3>
      </div>
      <div>
        <div
          v-if="
            btnRolrs[11] &&
            btnRolrs[11]['searchBarButtons'] &&
            btnRolrs[11]['searchBarButtons'].length
          "
        >
          <div v-if="btnRolrs[11] && btnRolrs[11].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[11].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="`商品收藏（${detail.goodsColNum}）`" name="1">
        <div></div>
      </el-tab-pane>
      <el-tab-pane :label="`店铺收藏（${detail.shopsColNum}）`" name="2">
        <div></div>
      </el-tab-pane>
    </el-tabs>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="商品编号" prop="skuCode" />
        <el-table-column align="center" label="商品名称" prop="goodsName" />
        <el-table-column align="center" label="收藏时间" prop="createTime" />
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import { getCollect, getCollectDetail } from '@/api/mall/distUsers/userDetail'
  import { checkButton } from '@/utils/mixins'
  export default {
    mixins: [checkButton],
    data() {
      return {
        activeName: '1',
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        userId: 0,
        goldenBeanAccount: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
        total: 0,
        accId: '',
        list: [],
        detail: {
          goodsColNum: 0,
          shopsColNum: 0,
          totalColNum: 0,
        },
      }
    },
    created() {
      const { goldenBeanAccount, accId } = this.$route.query
      this.goldenBeanAccount = goldenBeanAccount
      this.accId = accId
    },
    methods: {
      async fetchData(id) {
        this.listLoading = true
        this.userId = id
        const { data: collectDetail } = await getCollectDetail({
          userId: this.userId,
        })
        this.detail = collectDetail
        const {
          data: { records, total },
        } = await getCollect({
          userId: this.userId,
          ...this.queryForm,
        })
        this.total = total
        this.list = records
        this.listLoading = false
      },
      handleClick(e) {
        const { index } = e
        if (index == 0) {
          console.log('aaa')
          this.fetchData(this.userId)
        } else {
          console.log('bbb')
          this.fetchData(this.userId, 2)
        }
        this.queryForm = {
          current: 1,
          size: 10,
        }
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        if (this.activeName == 0) {
          this.fetchData(this.userId)
        } else {
          this.fetchData(this.userId, 2)
        }
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        if (this.activeName == 0) {
          this.fetchData(this.userId)
        } else {
          this.fetchData(this.userId, 2)
        }
      },
      // handleExport() {
      //   exportIntegral({
      //     accId: this.accId,
      //     userId: this.userId,
      //   })
      //     .then((res) => {
      //       const url = window.URL.createObjectURL(new Blob([res]))
      //       const link = document.createElement('a')
      //       link.setAttribute('href', url)
      //       link.setAttribute('download', new Date().getTime() + '.xlsx')
      //       link.click()
      //     })
      //     .catch(() => {
      //       this.$message.error('网络错误，请稍后重试！')
      //     })
      // },
    },
  }
</script>
