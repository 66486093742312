var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      staticStyle: { overflow: "auto", background: "#fff" },
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticStyle: { padding: "20px 10%", "margin-top": "65px" } },
        [
          _vm._m(1),
          _c(
            "el-collapse",
            {
              staticStyle: { "margin-top": "30px" },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "el-collapse-item",
                { key: index, attrs: { name: index } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "home-tip" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(item.updateOnline)) +
                            " "
                        ),
                      ]),
                      _c("span", [_vm._v(" " + _vm._s(item.versionNo) + " ")]),
                      _c("span", [
                        _vm._v(" " + _vm._s(item.versionName) + " "),
                      ]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "home-content",
                    domProps: { innerHTML: _vm._s(item.versionContent) },
                  }),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "header",
      { staticClass: "home-header", staticStyle: { padding: "0px 10%" } },
      [
        _c("div", { staticClass: "home-page" }, [
          _c("div", { staticClass: "home-logo" }, [_vm._v("虫客管理系统")]),
          _c("div"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", { staticStyle: { padding: "5px" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }