<template>
  <div class="comprehensive-table-container">
    <el-tabs :tab-position="'left'">
      <el-tab-pane label="基本信息">
        <common-form
          v-model="basicForm"
          :config="{}"
          :options="basicOptions"
          :rules="{}"
        />
      </el-tab-pane>
      <el-tab-pane label="规格信息">
        <common-form v-model="normsForm" :options="normsOptions">
          <template slot="tygg" v-if="normsForm.ggxx == '1'">
            <common-form v-model="guige" :options="oddOptions" />
          </template>
          <template slot="tygg" v-if="normsForm.ggxx == '2'">
            <el-row :gutter="20">
              <el-col :offset="0" :span="20">
                <multiple-spec />
              </el-col>
            </el-row>
          </template>
        </common-form>
      </el-tab-pane>
      <el-tab-pane label="积分赠送">
        <common-form v-model="giveForm" :options="giveOptions">
          <template slot="giveNumber" v-if="giveForm.jfzs == '是'">
            <el-form-item label="积分赠送">
              <el-input-number controls-position="right" />
            </el-form-item>
          </template>
        </common-form>
      </el-tab-pane>
      <el-tab-pane label="积分抵扣">
        <common-form v-model="deductionForm" :options="deductionOptions">
          <template slot="dkbl" v-if="deductionForm.jfdk == '是'">
            <el-form-item label="抵扣比例">
              <el-input-number controls-position="right" />
            </el-form-item>
          </template>
          <template slot="dkbl" v-if="deductionForm.jfdk == '是'">
            <el-form-item label="抵扣规则">
              <el-input-number controls-position="right" />
            </el-form-item>
          </template>
        </common-form>
      </el-tab-pane>
      <el-tab-pane label="商品详情">
        <common-form v-model="infoForm" :options="infoOptions" />
      </el-tab-pane>
    </el-tabs>
    <div class="text-c m-t">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button>取消</el-button>
    </div>
  </div>
</template>

<script>
  import { basicOptions } from '../const/basicOptions'
  import { normsOptions, oddOptions } from '../const/normsOptions'
  import { giveOptions } from '../const/giveOptions'
  import { deductionOptions } from '../const/deductionOptions'
  import { infoOptions } from '../const/infoOptions'
  import CommonForm from '@/components/CommonForm.vue'
  import MultipleSpec from '../operation/MultipleSpec.vue'
  export default {
    components: { CommonForm, MultipleSpec },
    data() {
      return {
        basicOptions,
        normsOptions,
        oddOptions,
        giveOptions,
        deductionOptions,
        infoOptions,
        basicForm: {
          bzfw: [],
        },
        normsForm: {},
        giveForm: {},
        deductionForm: {},
        infoForm: {},
        guige: {},
      }
    },
    created() {
      console.log(this.basicOptions)
    },
    methods: {
      save() {
        const data = {
          ...this.basicForm,
          ...this.normsForm,
          ...this.giveForm,
          ...this.deductionForm,
          ...this.infoForm,
          ...this.guige,
        }
        console.log('表单数据', data)
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>