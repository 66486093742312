var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "left" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息" } },
            [
              _c("common-form", {
                attrs: { config: {}, options: _vm.basicOptions, rules: {} },
                model: {
                  value: _vm.basicForm,
                  callback: function ($$v) {
                    _vm.basicForm = $$v
                  },
                  expression: "basicForm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "规格信息" } },
            [
              _c(
                "common-form",
                {
                  attrs: { options: _vm.normsOptions },
                  model: {
                    value: _vm.normsForm,
                    callback: function ($$v) {
                      _vm.normsForm = $$v
                    },
                    expression: "normsForm",
                  },
                },
                [
                  _vm.normsForm.ggxx == "1"
                    ? _c(
                        "template",
                        { slot: "tygg" },
                        [
                          _c("common-form", {
                            attrs: { options: _vm.oddOptions },
                            model: {
                              value: _vm.guige,
                              callback: function ($$v) {
                                _vm.guige = $$v
                              },
                              expression: "guige",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.normsForm.ggxx == "2"
                    ? _c(
                        "template",
                        { slot: "tygg" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { offset: 0, span: 20 } },
                                [_c("multiple-spec")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "积分赠送" } },
            [
              _c(
                "common-form",
                {
                  attrs: { options: _vm.giveOptions },
                  model: {
                    value: _vm.giveForm,
                    callback: function ($$v) {
                      _vm.giveForm = $$v
                    },
                    expression: "giveForm",
                  },
                },
                [
                  _vm.giveForm.jfzs == "是"
                    ? _c(
                        "template",
                        { slot: "giveNumber" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "积分赠送" } },
                            [
                              _c("el-input-number", {
                                attrs: { "controls-position": "right" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "积分抵扣" } },
            [
              _c(
                "common-form",
                {
                  attrs: { options: _vm.deductionOptions },
                  model: {
                    value: _vm.deductionForm,
                    callback: function ($$v) {
                      _vm.deductionForm = $$v
                    },
                    expression: "deductionForm",
                  },
                },
                [
                  _vm.deductionForm.jfdk == "是"
                    ? _c(
                        "template",
                        { slot: "dkbl" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抵扣比例" } },
                            [
                              _c("el-input-number", {
                                attrs: { "controls-position": "right" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.deductionForm.jfdk == "是"
                    ? _c(
                        "template",
                        { slot: "dkbl" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抵扣规则" } },
                            [
                              _c("el-input-number", {
                                attrs: { "controls-position": "right" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "商品详情" } },
            [
              _c("common-form", {
                attrs: { options: _vm.infoOptions },
                model: {
                  value: _vm.infoForm,
                  callback: function ($$v) {
                    _vm.infoForm = $$v
                  },
                  expression: "infoForm",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-c m-t" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("保存")]
          ),
          _c("el-button", [_vm._v("取消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }