import { render, staticRenderFns } from "./handover.vue?vue&type=template&id=01dbd7d9"
import script from "./handover.vue?vue&type=script&lang=js"
export * from "./handover.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01dbd7d9')) {
      api.createRecord('01dbd7d9', component.options)
    } else {
      api.reload('01dbd7d9', component.options)
    }
    module.hot.accept("./handover.vue?vue&type=template&id=01dbd7d9", function () {
      api.rerender('01dbd7d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall/health/qualityMeSale/components/handover/handover.vue"
export default component.exports