var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      staticClass: "version-information",
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("vab-icon", { attrs: { icon: "store-2-line" } }),
              _vm._v(" 销量排行 "),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-scrollbar",
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                data: _vm.tableDataPay,
                "show-summary": "",
                "summary-method": _vm.getSummaries,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "数据统计",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.callType == 1
                          ? _c("span", [_vm._v("订单数量")])
                          : _vm._e(),
                        scope.row.callType == 2
                          ? _c("span", [_vm._v("订单金额")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "今日订单",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.callType == 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.orderCount1))])
                          : _vm._e(),
                        scope.row.callType == 2
                          ? _c("span", [
                              _vm._v("￥" + _vm._s(scope.row.orderSum1)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "昨日订单",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.callType == 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.orderCount2))])
                          : _vm._e(),
                        scope.row.callType == 2
                          ? _c("span", [
                              _vm._v("￥" + _vm._s(scope.row.orderSum2)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "本周订单",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.callType == 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.orderCount3))])
                          : _vm._e(),
                        scope.row.callType == 2
                          ? _c("span", [
                              _vm._v("￥" + _vm._s(scope.row.orderSum3)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "本月订单",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.callType == 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.orderCount4))])
                          : _vm._e(),
                        scope.row.callType == 2
                          ? _c("span", [
                              _vm._v("￥" + _vm._s(scope.row.orderSum4)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }