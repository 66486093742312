import request from '@/utils/request'
// 设置列表
export function queryPage(data) {
  return request({
    url: `/mall/stp/stpPlatformConfig/queryPage`,
    method: 'post',
    data,
  })
}

// 设置编辑
export function editColumn(data) {
  return request({
    url: `/mall/stp/stpPlatformConfig/edit`,
    method: 'post',
    data,
  })
}
// 设置添加
export function addColumn(data) {
  return request({
    url: `/mall/stp/stpPlatformConfig/add`,
    method: 'post',
    data,
  })
}

// 设置状态
export function disableColumn(data) {
  return request({
    url: `/mall/stp/stpPlatformConfig/disable`,
    method: 'post',
    data,
  })
}
