<template>
  <el-card class="version-information" shadow="hover">
    <template #header>
      <vab-icon icon="store-2-line" />
      销量排行
    </template>
    <el-scrollbar>
      <el-table border :data="tableDataPay" show-summary :summary-method="getSummaries">
        <el-table-column align="center" label="数据统计" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.callType == 1">订单数量</span>
            <span v-if="scope.row.callType == 2">订单金额</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="今日订单" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.callType == 1">{{ scope.row.orderCount1 }}</span>
            <span v-if="scope.row.callType == 2">￥{{ scope.row.orderSum1 }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="昨日订单" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.callType == 1">{{ scope.row.orderCount2 }}</span>
            <span v-if="scope.row.callType == 2">￥{{ scope.row.orderSum2 }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="本周订单" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.callType == 1">{{ scope.row.orderCount3 }}</span>
            <span v-if="scope.row.callType == 2">￥{{ scope.row.orderSum3 }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="本月订单" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.callType == 1">{{ scope.row.orderCount4 }}</span>
            <span v-if="scope.row.callType == 2">￥{{ scope.row.orderSum4 }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
  </el-card>
</template>

<script>
import { dependencies, devDependencies } from '~/package.json'
import { statistics } from '@/api/mall/order/largescreen'
export default {
  data() {
    return {
      tableDataPay: [
        {
          sorts: '334',
          goodsName: '334',
          payVoucher: '334',
          amountType: '334',
        },
        {
          sorts: '334',
          goodsName: '334',
          payVoucher: '334',
          amountType: '334',
        },
      ],
      updateTime: process.env.VUE_APP_UPDATE_TIME,
      dependencies,
      devDependencies,
    }
  },
  created() {
    this.statistics()
  },
  methods: {
    statistics() {
      statistics({})
        .then((res) => {
          console.log(res.data)
          this.tableDataPay = res.data
        })
        .catch(() => {
          console.log('error')
        })
    },
    getSummaries(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (column.property == 'orderSum2') {
          sums[index] = (
            <div class="tkx-num">
              <span>年订单：</span>
            </div>
          )
        } else if (column.property == 'orderSum3') {
          sums[index] = (
            <div class="tkx-num">
              <span>年销售额：</span>
            </div>
          )
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
  },
}
</script>

<style lang="scss" scoped>
.version-information {
  ::v-deep .el-table th {
    .cell {
      max-width: 100px;
      text-align: left;
      text-indent: 0.5em;
    }
  }

  ::v-deep .el-table td {
    padding-left: -10px;
    background: white;
    .tkx-num {
      span {
        position: relative;
        display: inline-block;
        &::before {
          position: absolute;
          top: 50%;
          left: -23px;
          width: 15px;
          height: 15px;
          content: '';
          background: #fef8e8;
          border-radius: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .table {
    width: 100%;
    color: #666;
    border-collapse: collapse;
    td {
      position: relative;
      padding: 9px 15px;
      overflow: hidden;
      font-size: 14px;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 1px solid #e6e6e6;

      &:nth-child(odd) {
        width: 20%;
        text-align: right;
        background-color: #f7f7f7;
      }
    }
  }
}
</style>
