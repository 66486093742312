import { render, staticRenderFns } from "./IconList.vue?vue&type=template&id=295c68c9&scoped=true"
import script from "./IconList.vue?vue&type=script&lang=js"
export * from "./IconList.vue?vue&type=script&lang=js"
import style0 from "./IconList.vue?vue&type=style&index=0&id=295c68c9&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295c68c9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('295c68c9')) {
      api.createRecord('295c68c9', component.options)
    } else {
      api.reload('295c68c9', component.options)
    }
    module.hot.accept("./IconList.vue?vue&type=template&id=295c68c9&scoped=true", function () {
      api.rerender('295c68c9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/index/components/IconList.vue"
export default component.exports