/**
 * @description 电销-我的客户api
 * @author
 */
import request from '@/utils/request'

export function getPage(data) {
  return request({
    url: '/mall/userinfo/queryComPage',
    method: 'post',
    data: data,
  })
}

export function addObj(obj) {
  return request({
    url: '/upms/shopuser',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/upms/shopuser/' + id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/upms/shopuser/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/upms/shopuser',
    method: 'put',
    data: obj,
  })
}

export function editPassword(obj) {
  return request({
    url: '/upms/user/password',
    method: 'put',
    data: obj,
  })
}
// 1261193346845495297
// export function shopinfo(id) {
//   return request({
//     url: '/mall/shopinfo/' + id,
//     method: 'get',
//   })
// }
export function shopinfo(params = { current: 1, size: 10, type: 2 }) {
  return request({
    url: '/upms/shopuser/page',
    method: 'get',
    params,
  })
}

//pms/shopuser/page?current=1&size=20&type=2
// 指定员工
export function uuUserSys(data) {
  return request({
    url: '/mall/sys/uuUserSys/add',
    method: 'post',
    data,
  })
}
