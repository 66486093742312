import request from '@/utils/request'
// 二维码列表
export function queryPage(data) {
  return request({
    url: `/qr/wmsQrCode/queryPage`,
    method: 'post',
    data,
  })
}
// 新增二维码
export function add(data) {
  return request({
    url: `/qr/wmsQrCode/add`,
    method: 'post',
    data,
  })
}
// 编辑二维码
export function edit(data) {
  return request({
    url: `/qr/wmsQrCode/edit`,
    method: 'post',
    data,
  })
}
//操作状态
export function openOff(data) {
  return request({
    url: `/qr/wmsQrCode/openOff`,
    method: 'post',
    data,
  })
}
// 二维码模板下拉

export function queryList(data) {
  return request({
    url: `/qr/wmsQrCode/queryList`,
    method: 'post',
    data,
  })
}
