<template>
  <div class="comprehensive-table-container">
    <div class="ym_header">客户详细</div>
    <div class="content">
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark">体检时间</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark"> 年 月 日</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">手机号</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark"></div></el-col>
      </el-row>
      <el-row>
        <el-col :span="3"><div class="grid-content bg-purple-dark">姓名</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"> </div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">性别</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">年龄</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">患病年限</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
      </el-row>
      <el-row>
        <el-col :span="3"><div class="grid-content bg-purple-dark">身高 cm</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">体重 kg</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">BMI</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">腰围</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">血压</div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark">心率</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">是否规律用药</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="3"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">
          <el-checkbox label="1">是</el-checkbox>
          <el-checkbox label="2">否</el-checkbox>
        </div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6" ><div class="grid-content bg-purple-dark" style="height: 72px; line-height: 72px;">是否打针</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">胰岛素 <el-checkbox label=""/></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名：</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">剂量：</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">GLP-1 类 <el-checkbox label=""/> </div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名：</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">剂量：</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6" ><div class="grid-content bg-purple-dark" style="height: 146px; line-height: 146px;">是否口服药</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6" ><div class="grid-content bg-purple-dark" style="height: 146px; line-height: 146px;">是否口服药</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">药名： <el-checkbox label=""/></div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark">一天 <div class="textundeline"> </div>次,一次 <div class="textundeline"> </div>片，一片<div class="textundeline"> </div>mg</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark">是否会监测血糖</div></el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple-dark">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
            (空腹：<el-input v-model="dormdate.kf" placeholder="" style="max-width: 100px;"/>餐后2小时:<el-input v-model="dormdate.ch" placeholder="" style="max-width: 100px;"/>)
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark">是否出现低血糖</div></el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">出现时间和频率</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">
          <el-input v-model="dormdate.timpl" placeholder=""/>
        </div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark" style="height: 100px;">既往病史</div></el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple-dark" style="height: 100px;display: grid;grid-template-columns: repeat(5,1fr);">
            <el-checkbox label="以下均无"/>
            <el-checkbox label="高脂血症"/>
            <el-checkbox label="高血压"/>
            <el-checkbox label="高尿酸/痛风"/>
            <el-checkbox label="肾病"/>
            <el-checkbox label="胆结石/胆囊炎"/>
            <el-checkbox label="尿蛋白/尿微量白蛋白异常"/>
            <el-checkbox label="其他"/>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark" >既往史是否用药</div></el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
            <div style="margin-left: 20px;">药物名称：</div>
            <el-input v-model="dormdate.ywmc" placeholder=""/>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark" >住院史</div></el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <div style="margin-left: 20px;">药物名称：</div>
            <el-input v-model="dormdate.ywmc" placeholder=""/>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark" >脂肪肝</div></el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark" >家族史</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark" >
          <el-input v-model="dormdate.ywmc" placeholder=""/>
        </div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-dark" >最希望改善的地方</div></el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="血糖平稳"/>
            <el-checkbox label="减重"/>
            <el-checkbox label="减药"/>
            <el-checkbox label="停药"/>
            <el-checkbox label="预防/延缓并发症"/>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3"><div class="grid-content bg-purple-dark" style="height: 257px;">身体症状</div></el-col>
        <el-col :span="21">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            1、有无手麻、脚麻或皮肤瘙痒等症状？
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="21">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            2、有无失眠、多梦或便秘等症状？
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="21">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            3、是否有便秘、腹泻等症状？
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="21">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="21">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            5、有无视物模糊？
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="21">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            6、有无尿液泡沫增多？
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
        <el-col :span="21">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            7、有无糖尿病足部病变（如损伤后不容易愈合）？
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24"><div class="grid-content bg-purple-dark" >饮食生活调查问卷</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="grid-content bg-purple-dark" >1、平时吃一顿饭用时</div></el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            大概 <div class="textundeline"></div>分钟
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="grid-content bg-purple-dark" >2、晚上 8 点后会吃食物吗？</div></el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
            一周 <div class="textundeline"></div>次
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="grid-content bg-purple-dark" >3、三餐以外会吃食物/零食吗？</div></el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
            一周 <div class="textundeline"></div>次
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="grid-content bg-purple-dark" >4、是否常喝饮料/酒</div></el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
            一周 <div class="textundeline"></div>次
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="grid-content bg-purple-dark" >5、平时会运动吗？</div></el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
            一周 <div class="textundeline"></div>次
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="grid-content bg-purple-dark" >6、是否 12 点之前入睡？</div></el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="无"/>
            <el-checkbox label="有"/>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="grid-content bg-purple-dark" >7、睡眠质量好吗？</div></el-col>
        <el-col :span="16">
          <div class="grid-content bg-purple-dark" style="white-space: nowrap;">
            <el-checkbox label="好"/>
            <el-checkbox label="不好"/>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      dormdate:{

      }
    }
  },
  created(){
    console.log(this.$route.query.date)
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 80%;
}
  .ym_header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px dashed #ececec;
  }
  ::v-deep .el-col{
    border: 1px solid #b4b4b4
  }
  .bg-purple-dark{
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .textundeline{
    border-bottom: 1px solid #a3a3a3;
    height: 20px;
    min-width: 20px;
  }
</style>