var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comprehensive-table-container" }, [
    _c("div", { staticClass: "ym_header" }, [_vm._v("客户详细")]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("体检时间"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v(" 年 月 日"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("手机号"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("姓名"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("性别"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("年龄"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("患病年限"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("身高 cm"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("体重 kg"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("BMI"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("腰围"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("血压"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("心率"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("是否规律用药"),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 3 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _c("el-checkbox", { attrs: { label: "1" } }, [_vm._v("是")]),
                  _c("el-checkbox", { attrs: { label: "2" } }, [_vm._v("否")]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { height: "72px", "line-height": "72px" },
                },
                [_vm._v("是否打针")]
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("胰岛素 "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("药名："),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("剂量："),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("GLP-1 类 "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("药名："),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("剂量："),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { height: "146px", "line-height": "146px" },
                },
                [_vm._v("是否口服药")]
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { height: "146px", "line-height": "146px" },
                },
                [_vm._v("是否口服药")]
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _vm._v("药名： "),
                  _c("el-checkbox", { attrs: { label: "" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("一天 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("次,一次 "),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("片，一片"),
                _c("div", { staticClass: "textundeline" }),
                _vm._v("mg"),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("是否会监测血糖"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 18 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                  _vm._v(" (空腹："),
                  _c("el-input", {
                    staticStyle: { "max-width": "100px" },
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.dormdate.kf,
                      callback: function ($$v) {
                        _vm.$set(_vm.dormdate, "kf", $$v)
                      },
                      expression: "dormdate.kf",
                    },
                  }),
                  _vm._v("餐后2小时:"),
                  _c("el-input", {
                    staticStyle: { "max-width": "100px" },
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.dormdate.ch,
                      callback: function ($$v) {
                        _vm.$set(_vm.dormdate, "ch", $$v)
                      },
                      expression: "dormdate.ch",
                    },
                  }),
                  _vm._v(") "),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("是否出现低血糖"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("出现时间和频率"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.dormdate.timpl,
                      callback: function ($$v) {
                        _vm.$set(_vm.dormdate, "timpl", $$v)
                      },
                      expression: "dormdate.timpl",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { height: "100px" },
                },
                [_vm._v("既往病史")]
              ),
            ]),
            _c("el-col", { attrs: { span: 18 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: {
                    height: "100px",
                    display: "grid",
                    "grid-template-columns": "repeat(5,1fr)",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "以下均无" } }),
                  _c("el-checkbox", { attrs: { label: "高脂血症" } }),
                  _c("el-checkbox", { attrs: { label: "高血压" } }),
                  _c("el-checkbox", { attrs: { label: "高尿酸/痛风" } }),
                  _c("el-checkbox", { attrs: { label: "肾病" } }),
                  _c("el-checkbox", { attrs: { label: "胆结石/胆囊炎" } }),
                  _c("el-checkbox", {
                    attrs: { label: "尿蛋白/尿微量白蛋白异常" },
                  }),
                  _c("el-checkbox", { attrs: { label: "其他" } }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("既往史是否用药"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 18 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                  _c("div", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("药物名称："),
                  ]),
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.dormdate.ywmc,
                      callback: function ($$v) {
                        _vm.$set(_vm.dormdate, "ywmc", $$v)
                      },
                      expression: "dormdate.ywmc",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("住院史"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 18 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("div", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("药物名称："),
                  ]),
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.dormdate.ywmc,
                      callback: function ($$v) {
                        _vm.$set(_vm.dormdate, "ywmc", $$v)
                      },
                      expression: "dormdate.ywmc",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("脂肪肝"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("家族史"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                { staticClass: "grid-content bg-purple-dark" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.dormdate.ywmc,
                      callback: function ($$v) {
                        _vm.$set(_vm.dormdate, "ywmc", $$v)
                      },
                      expression: "dormdate.ywmc",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("最希望改善的地方"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 18 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "血糖平稳" } }),
                  _c("el-checkbox", { attrs: { label: "减重" } }),
                  _c("el-checkbox", { attrs: { label: "减药" } }),
                  _c("el-checkbox", { attrs: { label: "停药" } }),
                  _c("el-checkbox", { attrs: { label: "预防/延缓并发症" } }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 3 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { height: "257px" },
                },
                [_vm._v("身体症状")]
              ),
            ]),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 1、有无手麻、脚麻或皮肤瘙痒等症状？ "),
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 2、有无失眠、多梦或便秘等症状？ "),
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 3、是否有便秘、腹泻等症状？ "),
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 4、有无心慌、胸闷、气短、头晕头痛等心脑血管症状？ "),
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 5、有无视物模糊？ "),
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 6、有无尿液泡沫增多？ "),
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 7、有无糖尿病足部病变（如损伤后不容易愈合）？ "),
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 24 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("饮食生活调查问卷"),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("1、平时吃一顿饭用时"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" 大概 "),
                  _c("div", { staticClass: "textundeline" }),
                  _vm._v("分钟 "),
                ]
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("2、晚上 8 点后会吃食物吗？"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                  _vm._v(" 一周 "),
                  _c("div", { staticClass: "textundeline" }),
                  _vm._v("次 "),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("3、三餐以外会吃食物/零食吗？"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                  _vm._v(" 一周 "),
                  _c("div", { staticClass: "textundeline" }),
                  _vm._v("次 "),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("4、是否常喝饮料/酒"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                  _vm._v(" 一周 "),
                  _c("div", { staticClass: "textundeline" }),
                  _vm._v("次 "),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("5、平时会运动吗？"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                  _vm._v(" 一周 "),
                  _c("div", { staticClass: "textundeline" }),
                  _vm._v("次 "),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("6、是否 12 点之前入睡？"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "无" } }),
                  _c("el-checkbox", { attrs: { label: "有" } }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "grid-content bg-purple-dark" }, [
                _vm._v("7、睡眠质量好吗？"),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple-dark",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c("el-checkbox", { attrs: { label: "好" } }),
                  _c("el-checkbox", { attrs: { label: "不好" } }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }