import request from '@/utils/request'

export function queryPage(query) {
  return request({
    url: '/mall/vitExpert/page',
    method: 'get',
    params: query,
  })
}

export function addObj(obj) {
  return request({
    url: '/mall/vitExpert',
    method: 'post',
    data: obj,
  })
}
// 栏目编辑
export function editObj(obj) {
  return request({
    url: '/mall/vitExpert/edit',
    method: 'post',
    data: obj,
  })
}
export function getObj(id) {
  return request({
    url: '/mall/vitExpert/' + id.id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/mall/vitExpert/' + id.id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/mall/vitExpert',
    method: 'put',
    data: obj,
  })
}
// 配置类型（所有文章类型）
export function addColumnType(data) {
  return request({
    url: `/user/bbsArticleColumnType/add`,
    method: 'post',
    data,
  })
}

// 栏目状态
export function disableColumn(data) {
  return request({
    url: `/bbs/bbsArticleColumn/disable`,
    method: 'post',
    data,
  })
}

// 配置类型（所有文章类型）
export function queryListForList(data) {
  return request({
    url: `/bbs/bbsArticleType/queryListForList`,
    method: 'post',
    data,
  })
}
