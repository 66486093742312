<template>
  <div class="comprehensive-table-container">
    <common-form ref="commonForm" v-model="searchForm" :options="searchOptions">
      <template slot="operation">
        <el-button type="primary">搜索</el-button>
        <el-button>重置</el-button>
      </template>
    </common-form>
    <el-button
      class="addBtn"
      icon="el-icon-plus"
      type="primary"
      @click="handleAdd"
    >
      新增
    </el-button>
    <el-table border :data="data" >
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        align="center"
        label="昵称"
        prop="nickName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="账号"
        prop="username"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="店铺"
        prop="shopId"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="手机号"
        prop="phone"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="邮箱"
        prop="email"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="头像" prop="avatar">
        <template #default="{ row }">
          <el-image
            :lazy="true"
            :preview-src-list="[row.avatar]"
            :src="row.avatar"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="操作">
        <template #default="{ row }">
          <el-button size="default" type="text" @click="show(row)">
            查看
          </el-button>
          <el-button size="default" type="text" @click="edit(row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <table-edit
      ref="edit"
      v-model="form"
      :config="config"
      :options="options"
      :rules="rules"
      @fileStatus="fileupload"
      @submit="submit"
    />
  </div>
</template>

<script>
  // import {addObj, delObj, getPage, getObj, putObj, editPassword} from "@/api/upms/shopuser"
  import { getPage } from '@/api/mall/call/repurchase'
  import {
    options,
    rules,
    config,
    searchOptions,
  } from '@/const/call/repurchase'
  import { deepClone } from '@/utils/util'
  export default {
    data() {
      return {
        searchForm: { current: 1, size: 10 },
        total: 0,
        data: [],
        options,
        searchOptions,
        rules,
        config,
        form: {},
      }
    },
    created() {
      this.list()
    },
    methods: {
      list() {
        getPage(this.searchForm).then((res) => {
          const {
            data: { records, total },
          } = res
          console.log(records)
          this.data = records
          this.total = total
        })
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      fileupload(msg, result) {
        console.log('成功回调')
        console.log(msg, result)
      },
      submit() {
        // console.log('表单提交', e)
        console.log('表单数据user', this.form)
      },
      show(row) {
        this.form = deepClone(row)
        // this.$refs['edit'].setfile([{ url: row.avatar }])
        this.$nextTick(() => {
          this.$refs['edit'].showEdit('show')
        })
      },
      edit(row) {
        this.form = deepClone(row)
        // this.$refs['edit'].setfile([{ url: row.imgUrl }])
        this.$nextTick(() => {
          this.$refs['edit'].showEdit('edit')
        })
      },
    },
  }
</script>

<style></style>
