var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ym_select" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "selectId" },
      _vm._l(_vm.tabbtn, function (ym, index) {
        return _c(
          "div",
          {
            key: index,
            class:
              _vm.tabselect == index
                ? "item_select item_active"
                : "item_select",
            on: {
              click: function ($event) {
                return _vm.check(index)
              },
            },
          },
          [
            _c("div", { staticClass: "ym_center" }, [
              _c("div", { staticClass: "grop_" }, [_vm._v(_vm._s(ym.title))]),
              _c("div", { staticClass: "grop_l" }, [
                _vm._v(_vm._s(ym.subtitle)),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ym_header_title" }, [
      _vm._v(" 选择商品类型 "),
      _c("span", [_vm._v("商品保存后不允许修改。")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }