<template>
  <div>
    <el-dialog
      title="调整用户收益"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <div style="margin-bottom: 20px">
        <span>当前收益（可提现）</span>
        <span style="font-size: 20px; font-weight: 700">
          ￥{{ userDetail.profitAmount }}
        </span>
      </div>
      <el-form ref="ruleForm" label-width="100px" :model="form" :rules="rules">
        <el-form-item label="调整类型：" prop="amount">
          <div>
            <el-radio-group v-model="form.optType" @change="form.amount = 0">
              <el-radio-button :label="1">冻结</el-radio-button>
              <el-radio-button :label="2">解冻</el-radio-button>
            </el-radio-group>
          </div>
          <div :style="form.optType === 2 ? '' : 'margin-top: 20px'">
            <p v-if="form.optType === 2">
              冻结中收益：{{ profitDetail.frozenAmount }}
            </p>
            <el-input-number
              v-model="form.amount"
              :max="getMax"
              :min="0"
              :precision="2"
              :step="0.1"
            />
          </div>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <div>
            <el-input
              v-model="form.remark"
              placeholder="请输入备注"
              type="textarea"
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleConfirm()">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import { getProfit, updateProfit } from '@/api/mall/users/list'
  export default {
    data() {
      const checkNum = (rule, value, callback) => {
        const num = Number(value)
        if (isNaN(num)) {
          return callback(new Error('请输入数字值！'))
        }
        if (num < 0) {
          return callback(new Error('不能为负！'))
        }
        if (num > this.getMax) {
          return callback(new Error('非法参数！'))
        } else {
          callback()
        }
      }
      return {
        userDetail: {},
        profitDetail: {},
        form: {
          accId: '',
          optType: 1,
          amount: 0,
          remark: '',
        },
        dialogFormVisible: false,
        rules: {
          remark: [
            { required: true, message: '备注信息不能为空！', trigger: 'blur' },
          ],
          amount: [{ validator: checkNum, trigger: 'blur' }],
        },
      }
    },
    computed: {
      getMax() {
        let maxnum = 0
        if (this.form.optType === 1) {
          maxnum = this.userDetail.profitAmount
        } else if (this.profitDetail && this.profitDetail.frozenAmount) {
          maxnum = this.profitDetail.frozenAmount
        }
        return maxnum
      },
    },
    methods: {
      checkInput(e) {
        console.log(e)
      },
      async show(data) {
        const { id: userId, accId } = data
        this.userDetail = data
        this.form.accId = accId
        const { data: profitDetail } = await getProfit({ userId })
        this.profitDetail = profitDetail
        this.dialogFormVisible = true
      },
      close() {
        this.$emit('fetch')
        this.resetForm('ruleForm')
        this.dialogFormVisible = false
      },
      handleConfirm() {
        const obj = { ...this.form, amount: this.form.amount }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            updateProfit(obj).then(() => {
              this.$message.success('操作成功！')
              this.close()
            })
          } else {
            this.$message.error('参数错误！请重新配置')
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
    },
  }
</script>
