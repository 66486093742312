<template>
  <div>
    <!-- 发起呼叫 -->
    <button @click="regisetPhone()">注 册</button>
    <button @click="unregisetPhone()">注 销</button>
    <input v-model="calledPhone" type="text" />
    <button @click="callPhone()">拨 打</button>
    <button @click="hangupPhone()">挂 机</button>
    <button @click="startWeb()">链接webSocket</button>
    <button @click="stopWeb()">断开webSocket</button>
    <!-- 媒体播放dom树 -->
    <audio id="remoteAudio" autoPlay />
  </div>
</template>

<script>
let sdk = require("./js/sdk.min.js")
export default {
  data() {
    return {
      phoneUa: null,
      calledPhone: null,
    }
  },
  mounted() {
    this.callPhoneDial()
  },
  methods: {
    regisetPhone() {
      let extCode = "API返回坐席工号"
      let addr = "API返回IP对接地址"
      let extPass = "API返回坐席密码"
      this.phoneUa = sdk.SIP.SDK.cxWebPhone({
        userName: extCode,
        password: extPass,
        server: addr,
        traceSip: false,
        builtinEnabled: true,
        audioElement: "remoteAudio",
        in: {
          terminated: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          accepted: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          bye: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          rejected: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          failed: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
        },
        ua: {
          invite: (response, type) => this.webPhoneUa(response, type),
          registered: (response, type) => this.webPhoneUa(response, type),
          unregister: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          registrationFailed: (response, cause, type) =>
            this.webPhoneEvent(response, type),
          connecting: (response, type) => this.webPhoneUa(response, type),
          connected: (response, type) => this.webPhoneUa(response, type),
          disconnecting: (response, type) => this.webPhoneUa(response, type),
          disconnected: (response, type) => this.webPhoneUa(response, type),
        },
      })
    },
    callPhone() {
      let callPhone = this.calledPhone
      sdk.SIP.SDK.callWebPhone({
        phoneNumber: callPhone,
        out: {
          progress: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          accepted: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          rejected: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          failed: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          terminated: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
          bye: (response, cause, type) =>
            this.webPhoneEvent(response, cause, type),
        },
      })
    },
    webPhoneEvent(response, cause, type) {
      console.warn("type,response, cause", type, response, cause)
    },
    webPhoneUa(response, type) {
      console.warn("type,response", type, response)
      if (type === "ua.invite") {
        var msg = response.remoteIdentity.uri.user + "来电了，是否接听"
        if (confirm(msg) == true) {
          response.answer()
        } else {
          response.reject()
        }
      }
    },
    hangupPhone() {
      this.phoneUa.hangup()
    },
    unregisetPhone() {
      this.phoneUa.unregister()
    },
    startWeb() {
      this.phoneUa.start()
    },
    stopWeb() {
      this.phoneUa.stop()
    },
  },
}
</script>
<style scoped>
</style>