<template>
  <div class="ym_select">
    <div class="ym_header_title">
      选择商品类型
      <span>商品保存后不允许修改。</span>
    </div>
    <div class="selectId">
      <div
        v-for="(ym, index) in tabbtn"
        :key="index"
        :class="tabselect == index ? 'item_select item_active' : 'item_select'"
        @click="check(index)"
      >
        <div class="ym_center">
          <div class="grop_">{{ ym.title }}</div>
          <div class="grop_l">{{ ym.subtitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabbtn: [
          { title: '实体商品', subtitle: '物流发货' },
          { title: '实体商品', subtitle: '物流发货' },
        ],
        tabselect: 0,
      }
    },
    methods: {
      check(e) {
        this.tabselect = e
      },
    },
  }
</script>

<style lang="scss" scoped>
  .ym_select {
    .ym_header_title {
      display: flex;
      align-items: center;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
      font-weight: bold;
      color: #606266;
      span {
        margin-left: 10px;
        font-weight: 500;
        color: gray;
      }
    }
    .selectId {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
      margin: 20px 0;
      &::before {
        position: absolute;
        bottom: -20px;
        width: 100%;
        height: 10px;
        content: '';
        background: rgb(246, 248, 249);
      }
      .item_select {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 150px;
        max-width: 150px;
        height: 100px;
        margin-right: 20px;
        text-align: center;
        cursor: pointer;
        background: rgb(249, 249, 252);
        border: 1px solid #ececec;
        border-radius: 10px;
        .grop_ {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
      .item_active {
        color: #1890ff;
        background: #dde3e752;
        border: 1px solid #c3e3f852;
      }
    }
  }
</style>
