var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "common-form",
        {
          ref: "commonForm",
          attrs: { options: _vm.searchOptions },
          model: {
            value: _vm.searchForm,
            callback: function ($$v) {
              _vm.searchForm = $$v
            },
            expression: "searchForm",
          },
        },
        [
          _c(
            "template",
            { slot: "operation" },
            [
              _c("el-button", { attrs: { type: "primary" } }, [_vm._v("搜索")]),
              _c("el-button", [_vm._v("重置")]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-button",
        {
          staticClass: "addBtn",
          attrs: { icon: "el-icon-plus", type: "primary" },
          on: { click: _vm.handleAdd },
        },
        [_vm._v(" 新增 ")]
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.data } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "60",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "昵称",
              prop: "nickName",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "账号",
              prop: "username",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "店铺",
              prop: "shopId",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "手机号",
              prop: "phone",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "邮箱",
              prop: "email",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "头像", prop: "avatar" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-image", {
                      attrs: {
                        lazy: true,
                        "preview-src-list": [row.avatar],
                        src: row.avatar,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.show(row)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("table-edit", {
        ref: "edit",
        attrs: { config: _vm.config, options: _vm.options, rules: _vm.rules },
        on: { fileStatus: _vm.fileupload, submit: _vm.submit },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }