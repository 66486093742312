<template>
  <div class="container" style="overflow: auto; background: #fff">
    <header class="home-header" style="padding: 0px 10%">
      <div class="home-page">
        <div class="home-logo">虫客管理系统</div>
        <div></div>
      </div>
    </header>
    <div style="padding: 20px 10%; margin-top: 65px">
      <div>
        <p style="padding: 5px"></p>
      </div>
      <el-collapse
        v-model="activeNames"
        style="margin-top: 30px"
        @change="handleChange"
      >
        <el-collapse-item
          v-for="(item, index) in list"
          :key="index"
          :name="index"
        >
          <template slot="title">
            <div class="home-tip">
              <span>
                {{ item.updateOnline | formatDate }}
              </span>
              <span>
                {{ item.versionNo }}
              </span>
              <span>
                {{ item.versionName }}
              </span>
            </div>
          </template>
          <div class="home-content" v-html="item.versionContent"></div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
  import { queryListVer } from '@/api/mall/role/versionManage'
  export default {
    data() {
      return {
        activeNames: [0],
        list: [],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      // 列表
      async fetchData() {
        await queryListVer()
          .then((res) => {
            if (res.data) {
              this.list = res.data
            }
          })
          .catch(() => {})
      },
      handleChange(val) {
        console.log(val)
      },
    },
  }
</script>
<style scoped lang="scss">
  .container {
    height: 100vh;
  }
  ::v-deep(.home-content) {
    p {
      margin: 0px;
    }
  }
  .home-header {
    position: fixed !important;
    z-index: 999999;
    width: 100%;
    background: #fff;
    box-shadow: 0 5px 8px rgb(0 0 0 / 15%);
    .home-page {
      position: relative;
      height: 100%;
      margin: auto;
      will-change: transform;
    }
    .home-logo {
      position: relative;
      display: inline-block;
      width: 170px;
      font-size: 18px;
      font-weight: 700;
      line-height: 64px;
      color: #1890ff;
      text-decoration: none;
      cursor: pointer;
      background-color: transparent;
      outline: none;
      transition: color 0.3s;
    }
  }
  .home-tip {
    overflow: hidden;
    span {
      float: left;
      margin-right: 20px;
    }
  }
  ::v-deep(.el-collapse) {
    border: 1px solid #d9d9d9;
    .el-collapse-item__header {
      height: auto;
      padding: 20px;
      line-height: normal;
      color: rgba(0, 0, 0, 0.85);
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      border-top: 0;
      border-right: 0;
      border-left: 0;
    }
    .el-collapse-item__content {
      padding: 25px 20px 20px 20px;
    }
  }
</style>
