const options = [
  { label: '商品名称', span: 24, required:true,prop: 'spmc' ,type:'textarea'},
  {
    label: '店铺',
    span: 12,
    prop: 'dianpu',
    type: 'select',
    optionData: [{ label: 'Apple 正品', value: 1 }],
  },
  {
    label: '运费模板',
    span: 12,
    prop: 'yunfei',
    type: 'select',
    optionData: [{ label: 'Apple 正品', value: 1 }],
  },
  { label: '商品图片', span: 24, prop: 'sp', type: 'upload' },
  { label: '商城类目', span: 12, prop: 'sclm', type: 'select' },
  { label: '店铺类目', span: 12, prop: 'dplm', type: 'select' },
  {
    label: '是否上架',
    span: 12,
    prop: 'sfsj',
    type: 'radio',
    optionData: [
      { label: '是', value: 1 },
      { label: '否', value: 2 },
    ],
  },
  { label: '商品编码', span: 12, prop: 'spbm' },
  { label: '虚拟销量', span: 12, prop: 'xnxl', type: 'number' },
  { label: '卖点', span: 24, prop: 'h' },
  {
    label: '保障服务',
    span: 24,
    prop: 'bzfw',
    type: 'checkbox',
    default: [],
    optionData: [
      { label: '假一赔十', value: 1 },
      { label: '七天无理由退换', value: 2 },
      { label: '24小时发货', value: 3 },
      { label: '5年质量保障', value: 4 },
    ],
  },
]

const rules = {
  c: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}
const config = { labelWidth: '8em' }
export { options as basicOptions, rules, config }
