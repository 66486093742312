<template>
  <div v-loading.sync="listLoading" style="height: 69vh; padding: 10px">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div style="display: flex; align-items: center">
        <h3 style="margin-right: 10px">当前机构</h3>
        <h3 style="color: rgb(37, 115, 217)">{{ total }}</h3>
      </div>
      <div>
        <div
          v-if="
            btnRolrs[14] &&
            btnRolrs[14]['searchBarButtons'] &&
            btnRolrs[14]['searchBarButtons'].length
          "
        >
          <div v-if="btnRolrs[14] && btnRolrs[14].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[14].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="机构名称" prop="orgName" />
        <el-table-column align="center" label="机构区域" prop="treeNames" />
        <el-table-column align="center" label="机构地址" prop="orgAddr" />
        <el-table-column align="center" label="机构职位" prop="typeName" />
        <el-table-column align="center" label="加入时间" prop="createTime" />
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import { getUserOrg, exportIntegral } from '@/api/mall/distUsers/userDetail'
  import { checkButton } from '@/utils/mixins'
  export default {
    mixins: [checkButton],
    inject: ['userDetail'],
    data() {
      return {
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        userId: 0,
        goldenBeanAccount: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
        total: 0,
        accId: '',
        list: [],
      }
    },
    created() {
      const { goldenBeanAccount, accId } = this.$route.query
      this.goldenBeanAccount = goldenBeanAccount
      this.accId = accId
    },
    methods: {
      async fetchData(id) {
        this.listLoading = true
        this.userId = id
        const {
          data: { list, total },
        } = await getUserOrg({ userId: this.userId })
        this.total = total
        this.list = list
        this.listLoading = false
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        this.fetchData()
      },
      handleExport() {
        exportIntegral({
          accId: this.accId,
          userId: this.userId,
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', new Date().getTime() + '.xlsx')
            link.click()
          })
          .catch(() => {
            this.$message.error('网络错误，请稍后重试！')
          })
      },
    },
  }
</script>
