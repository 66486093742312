<template>
  <div>
    <el-dialog
  :before-close="handleClose"
  title="提示"
  :visible.sync="dialogVisible"
  width="60%">
  <span>
    <el-transfer v-model="value" :data="data" filter-placeholder="请输入城市拼音" filterable />
  </span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="clear">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
  </div>

</template>

<script>
export default {
  props:{
    showbloan:{
      type:Boolean,
      default:()=>{}
    }
  },
  data() {
    const generateData = () => {
      const data = []
      const cities = ['上海', '北京', '广州', '深圳', '南京', '西安', '成都']
      const pinyin = ['shanghai', 'beijing', 'guangzhou', 'shenzhen', 'nanjing', 'xian', 'chengdu']
      cities.forEach((city, index) => {
        data.push({
          label: city,
          key: index,
          pinyin: pinyin[index],
        })
      })
      return data
    }
    return {
      data: generateData(),
      value: [],
      dialogVisible:this.showbloan
    }
  },
  watch:{
    showbloan(newValue,oldValue){
      if(newValue!=oldValue){
        this.dialogVisible=newValue
      }
    }
  },
  methods:{
    handleClose(){},
    clear(){
      this.$emit('closerecy',false)
    }
  },
}
</script>