import request from '@/utils/request'
// 出库列表
export function queryDepotOutListForPage(data) {
  return request({
    url: `/out/wmsDepotOut/queryDepotOutListForPage`,
    method: 'post',
    data,
  })
}
//商品列表（ 条码查询）
export function queryDepotInGoodsListForPage(data) {
  return request({
    url: `/depot/wmsDepotInGoods/queryDepotInGoodsListForPage`,
    method: 'post',
    data,
  })
}
// 添加商品出库
export function addDepotOutGoodsInfo(data) {
  return request({
    url: `/out/wmsDepotOut/addDepotOutGoodsInfo`,
    method: 'post',
    data,
  })
}
// 箱码打码

export function addDepotInBoxInfo(data) {
  return request({
    url: `/out/wmsDepotOut/addDepotInBoxInfo`,
    method: 'post',
    data,
  })
}
// 列表打码数量
export function editGoodsCodeInfo(data) {
  return request({
    url: `/out/wmsDepotOutGoods/editGoodsCodeInfo`,
    method: 'post',
    data,
  })
}
// 查看出库单

export function queryDepotOutGoodsListForPage(data) {
  return request({
    url: `/out/wmsDepotOutGoods/queryDepotOutGoodsListForPage`,
    method: 'post',
    data,
  })
}
