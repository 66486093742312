<template>
  <div class="comprehensive-table-container">
    <common-form ref="commonForm" v-model="searchForm" :options="searchOptions">
      <template slot="operation">
        <el-button type="primary">搜索</el-button>
        <el-button>重置</el-button>
      </template>
    </common-form>
    <div class="m-b">
      <!-- <el-button type="primary" @click="handleAdd">新增</el-button> -->
      <el-button type="primary">批量操作</el-button>
    </div>
    <el-table border :data="data">
      <el-table-column align="center" type="selection" width="55" />
      <!-- <el-table-column align="center" label="序号" type="index" width="60" /> -->
      <el-table-column
        align="center"
        label="编码"
        prop="userCode"
        show-overflow-tooltip
        width="70"
      />
      <el-table-column align="center" label="头像" prop="headimgUrl">
        <template #default="{ row }">
          <el-image
            :lazy="true"
            :preview-src-list="[row.headimgUrl]"
            :src="row.headimgUrl"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="昵称"
        prop="nickName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="手机号"
        prop="phone"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="性别">
        <template #default="{ row }">
          <!-- <div>{{ row.sex | getSex }}</div> -->
          <span v-if="row.sex == 2">女</span>
          <span v-else-if="row.sex == 1">男</span>
          <span v-else-if="row.sex == 0">保密</span>
          <span v-else>{{ row.sex }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="所在城市"
        prop="city"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="所属员工"
        prop="city"
        show-overflow-tooltip
      />

      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="操作">
        <template #default="{ row }">
          <el-button size="default" type="text" @click="show(row)">
            查看
          </el-button>
          <el-button size="default" type="text" @click="edit(row)">
            编辑
          </el-button>
          <el-button size="medium" type="text" @click="appoint(row)">
            指定员工
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="指定员工" :visible.sync="appointDialog" width="60%">
      <div class="flex-center">
        <el-transfer
          v-model="appointUser"
          :data="appointData"
          filterable
          :props="{
            key: 'id',
            label: 'username',
          }"
          :titles="['员工列表', '指定员工']"
          @change="teaChange"
          @left-check-change="leftChange"
          @right-check-change="rightChange"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appointDialog = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
    <table-edit
      ref="edit"
      v-model="form"
      :config="config"
      :options="options"
      :rules="rules"
      @fileStatus="fileupload"
      @submit="submit"
    />
  </div>
</template>

<script>
  // import {addObj, delObj, getPage, getObj, putObj, editPassword} from "@/api/mall/seas/seasManger"
  import { getPage, shopinfo, uuUserSys } from '@/api/mall/seas/seasManger'
  import {
    options,
    rules,
    config,
    searchOptions,
  } from '@/const/seas/seasManger'
  import { deepClone } from '@/utils/util'
  export default {
    filters: {
      //性别（1：男，2：女，0：未知）
      getSex(val) {
        let str = ''
        switch (val) {
          case 1:
            str = '男'
            break
          case 2:
            str = '女'
            break
          case 0:
            str = '未知'
            break
        }
        return str
      },
    },
    data() {
      return {
        appointUser: [],
        appointData: [{ label: '上海', key: '1', disabled: false }],
        listNew: [], //暂存list，用于多选时赋值
        searchForm: { current: 1, size: 10 },
        total: 0,
        data: [],
        options,
        searchOptions,
        rules,
        config,
        form: {},
        appointDialog: false,
        // 当前点击的指定id
        userId: 0,
      }
    },
    created() {
      this.list()
      this.getUserList()
    },
    methods: {
      async getUserList() {
        // console.log('mall/shopinfo/page')
        shopinfo().then((res) => {
          console.log(res)
          const {
            data: { records },
          } = res
          console.log(records)
          this.appointData = records
        })
      },
      list() {
        getPage(this.searchForm).then((res) => {
          const {
            data: { records, total },
          } = res
          console.log(records)
          this.data = records
          this.total = total
        })
      },
      appoint(row) {
        this.userId = row.userId
        this.appointDialog = true
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      fileupload(msg, result) {
        console.log('成功回调')
        console.log(msg, result)
      },
      submit() {
        // console.log('表单提交', e)
        console.log('表单数据user', this.form)
      },
      show(row) {
        this.form = deepClone(row)
        this.$nextTick(() => {
          this.$refs['edit'].showEdit('show')
        })
      },
      edit(row) {
        this.form = deepClone(row)
        this.$nextTick(() => {
          this.$refs['edit'].showEdit('edit')
        })
      },
      leftChange(e) {
        this.appointData.forEach((item) => {
          if (e[0] == item.id) item.disabled = false
          else item.disabled = true
        })
      },
      rightChange(e) {
        console.log(e)
      },
      teaChange(e) {
        if (!e.length) {
          this.appointData.forEach((item) => {
            item.disabled = false
          })
        }
      },
      sure() {
        if (this.userId == 0) {
          return this.$baseMessage('error', 'error', 'vab-hey-message-success')
        }
        if (this.appointUser.length <= 0) {
          return this.$baseMessage(
            '至少指定一个员工',
            'error',
            'vab-hey-message-success'
          )
        }
        uuUserSys({ sysUserId: this.appointUser[0], userId: this.userId }).then(
          (res) => {
            this.$baseMessage(
              res.code == 0 ? res.data : res.msg,
              res.code == 0 ? 'success' : 'error',
              'vab-hey-message-success'
            )
            this.appointDialog = false
            this.appointUser = []
            this.getUserList()
          }
        )
      },
    },
  }
</script>

<style></style>
