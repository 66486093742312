<template>
  <div class="OrderList cy-container">
    <div class="GeneralOrder">
      <!-- 查询-->
      <div class="filterOrderFrom">
      
      </div>
      <div class="m-b">
        <el-button
          v-for="item in searchButton"
          :key="item.id"
          type="primary"
          @click.native="handleClickBtn(item)"
        >
          {{ item.buttonName }}
        </el-button>
      </div>
      <!-- 列表 -->
      <el-table
        v-loading="listLoading"
        border
        :data="orderList"
        default-expand-all
        max-height="600"
        row-key="id"
        @selection-change="setSelectRows"
      >
        <el-table-column
          align="center"
          show-overflow-tooltip
          type="selection"
        />
        <el-table-column
          align="center"
          label="订单编号"
          prop="orderNo"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          :formatter="formatterShop"
          label="店铺名称"
          prop="shopId"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="买家"
          prop="phone"
          show-overflow-tooltip
        >
          <!-- <template #default="{ row }">
            <span class="colorBlue">{{ row.phone }}</span>
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          :formatter="formatterOrderType"
          label="订单类型"
          prop="orderType"
          show-overflow-tooltip
        />

        <el-table-column
          align="center"
          label="订单金额"
          prop="salesPrice"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <span>{{ row.salesPrice | numFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :formatter="formatterOrder"
          label="订单状态"
          prop="status"
          show-overflow-tooltip
        />

        <el-table-column
          align="center"
          :formatter="formatterOrderDeliveryWay"
          label="配送方式"
          prop="deliveryWay"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="下单时间"
          prop="createTime"
          show-overflow-tooltip
        />
        <el-table-column v-if="operationShow" align="center" label="操作">
          <template #default="{ row }">
            <template v-for="item in listButton">
              <el-button
                v-if="showListButton(row.orderState, item)"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                size="mini"
                :type="item.buttonCss"
                @click.native="handleClickBtn(item, row)"
              >
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        :current-page="forms.current"
        :layout="layout"
        :page-size="forms.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
  import {
    getOrderList,
    getOrderDetail,
    exportMallOrder,
  } from '@/api/mall/order/order'
  import { getList } from '@/api/mall/shop/info'
  import { selectSysDict, selectRoleMenuButtonList } from '@/api/mall/common'
  import { timestamp } from '@/utils/index'
  import { saveAs } from 'file-saver'
  export default {
    data() {
      return {
        btnStatus: {
          20: ['handleEditAdr', 'handelDelivery'],
          16: ['handleEditAdr', 'handelDelivery'],
        },
        operationShow: false, //按钮权限
        searchButton: [],
        listButton: [],
        isShow: true, //表格分页数据
        listLoading: true,
        forms: { current: 1, size: 10 },
        queryForm: {
          orderState: '',
          orderType: '',
          orderCode: '',
          phone: '',
          shopName: '',
          createTime: '',
          endCreateTime: '',
          orderTimes: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        orderListStateList: [], // 订单状态
        orderList: [],
        comOrderTypeList: [], //订单类型
        OrderDeliveryWayList: [], //订单配送
        orderDetails: {},
        downloadLoading: false,
        shopList: [], //店铺集合
      }
    },

    created() {
      this.fetchData()
      this.selectSysDict()
      this.selectRoleMenuButtonList()
      this.getListSHop()
    },

    methods: {
      // 导出
      handleExport() {
        this.downloadLoading = true
        const listQueryData = {}
        listQueryData.orderCode = this.queryForm.orderCode
        listQueryData.orderType = this.queryForm.orderType
        listQueryData.orderState = this.queryForm.orderState
        listQueryData.shopName = this.queryForm.shopName
        listQueryData.createTime = this.queryForm.createTime
        listQueryData.endCreateTime = this.queryForm.endCreateTime
        exportMallOrder(listQueryData)
          .then((response) => {
            saveAs(response, '订单' + '.xlsx')
          })
          .catch(() => {
            this.ME('请稍后再试')
          })
        this.downloadLoading = false
      },
      getorder() {
        this.fetchData()
        this.selectSysDict()
        this.selectRoleMenuButtonList()
      },

      // 发货
      handelDelivery(row) {
        // 详情数据
        getOrderDetail({ id: row.id })
          .then((res) => {
            if (res.data) {
              this.orderDetails = res.data
            }
          })
          .catch(() => {})
        this.$refs['delivery'].showEdit()
      },
      // 修改收货地址
      handleEditAdr(row) {
        this.$refs['eidtAddress'].showEdit(row)
      },
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
      },
      // 按钮状态显示判断
      showListButton(states, item) {
        if (item.buttonUrl == 'handleLook') {
          return true
        }
        if (states == 20 && this.btnStatus[20].indexOf(item.buttonUrl) != -1) {
          return true
        }
        if (states == 16 && this.btnStatus[16].indexOf(item.buttonUrl) != -1) {
          return true
        }
        return false
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: 1, //this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      // 订单类型
      formatterOrderType(row) {
        for (let i = 0; i < this.comOrderTypeList.length; i++) {
          if (this.comOrderTypeList[i].dictKey == row.orderType) {
            return this.comOrderTypeList[i].dictValue
          }
        }
      },
      // 订单状态过滤
      formatterOrder(row) {
        for (let i = 0; i < this.orderListStateList.length; i++) {
          if (this.orderListStateList[i].dictKey == row.status) {
            return this.orderListStateList[i].dictValue
          }
        }
      },
      // 订单配送方式
      formatterOrderDeliveryWay(row) {
        for (let i = 0; i < this.OrderDeliveryWayList.length; i++) {
          if (this.OrderDeliveryWayList[i].dictKey == row.deliveryWay) {
            return this.OrderDeliveryWayList[i].dictValue
          }
        }
      },
      // 字典
      selectSysDict() {
        selectSysDict({
          dictTypeList: ['orderStatus', 'comOrderType', 'mallPostType'],
        })
          .then((res) => {
            if (res.data) {
              this.orderListStateList = res.data[0].dictInfoVoList
              this.comOrderTypeList = res.data[1].dictInfoVoList
              this.OrderDeliveryWayList = res.data[2].dictInfoVoList
            }
          })
          .catch(() => {})
      },
      // 查询店铺
      getListSHop() {
        // 详情数据
        getList({})
          .then((res) => {
            if (res.data) {
              this.shopList = res.data
            }
          })
          .catch(() => {})
      },

      formatterShop(row) {
        for (let i = 0; i < this.shopList.length; i++) {
          if (this.shopList[i].id == row.shopId) {
            return this.shopList[i].name
          }
        }
      },
      // 下单时间
      dateChange(date) {
        if (date && date.length) {
          this.queryForm.createTime = date[0]
          this.queryForm.endCreateTime = date[1]
        } else {
          this.queryForm.createTime = ''
          this.queryForm.endCreateTime = ''
        }
      },
      // 时间格式化
      dateFormat(row, colum, cellValue) {
        return timestamp(cellValue)
      },
      // 订单列表数据
      async fetchData(options) {
        this.listLoading = true
        await getOrderList({
          ...this.forms,
          ...options,
        })
          .then((res) => {
            if (res.data) {
              this.orderList = res.data.records
              this.total = res.data.total
            } else {
              this.listLoading = false
            }
          })
          .catch(() => {})
        this.listLoading = false
      },
      // 表格操作
      setSelectRows(val) {
        this.selectRows = val
      },
      // 查看详情
      handleLook(row) {
        if (row.id) {
          this.$router.push({
            path: '/orderDetail',
            query: { orderId: row.id },
          })
        }
      },

      // 批量发货
      handleGoods() {},
      // 导出待发货订单
      handleExpOrder() {},
      // 搜索
      async handleQuery() {
        // this.isShow = false
        // console.log(this.isShow)
        // this.forms.current = 1
        // this.fetchData(this.queryForm)
        this.forms.current = 1
        await this.fetchData({ ...this.queryForm }, false)
        let temp = false
        Object.keys(this.queryForm).forEach((key) => {
          this.queryForm[key] && (temp = true)
        })
        this.isShow = temp
      },
      // 重置
      async handleref() {
        this.forms.current = 1
        this.forms.size = 10
        this.createTime = ''
        this.endCreateTime = ''
        this.queryForm = {}
        this.handleQuery()
      },

      // 分页
      handleSizeChange(val) {
        this.forms.size = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
      // 分页
      handleCurrentChange(val) {
        this.forms.current = val
        let data = this.isShow === true ? this.queryForm : {}
        this.fetchData({ ...data }, false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .OrderList {
    padding: 20px;

    ::v-deep(.el-tabs__nav) {
      width: 15%;

      .el-tabs__item {
        width: 100%;
        text-align: center;
      }
    }
  }

  .filterItems {
    width: 185px;
  }

  .GeneralOrder {
    padding: 10px;

    .filterOrderFrom {
      .el-form-item:first-child,
      .el-form-item + .el-form-item {
        margin-right: 15px !important;
      }

      .el-form-item__label {
        padding: 0 5px 0 0;
      }

      .orderCreatTime {
        .el-date-editor {
          width: 210px;
        }
      }

      .left-panel {
        margin-bottom: 0px;
      }
    }
  }
</style>
