const options = [
  { label: '用户初始积分', span: 12, prop: 'nickName1', type: 'number' },
  { label: '订单满', span: 12, prop: 'nickName2', type: 'number' },
  { label: '抵扣比例', span: 12, prop: 'nickName3', type: 'number' },
  { label: '抵扣规则', span: 12, prop: 'nickName4', type: 'number' },
]

const rules = {
  a: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}

const searchOptions = []
const config = { labelWidth: '7em' }
export { options, rules, config, searchOptions }
