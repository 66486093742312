import request from '@/utils/request'
// 进货订单列表
export function queryPage(data) {
  return request({
    url: '/order/ordShopOrder/queryPage',
    method: 'post',
    data,
  })
}
// 进货订单详情（订单、付款信息、配送方式、订单状态）
export function selectShopOrder(data) {
  return request({
    url: '/order/ordShopOrder/selectShopOrder',
    method: 'post',
    data,
  })
}
// 进货订单详情（收货人信息）
export function selectConsignee(data) {
  return request({
    url: '/order/ordShopOrder/selectConsignee',
    method: 'post',
    data,
  })
}
// 进货订单详情（购买人、机构信息）
export function selectOrderUserOrg(data) {
  return request({
    url: '/order/ordShopOrder/selectOrderUserOrg',
    method: 'post',
    data,
  })
}
// 进货订单详情（商品信息）
export function selectOrderGoods(data) {
  return request({
    url: '/order/ordShopOrder/selectOrderGoods',
    method: 'post',
    data,
  })
}

// 进货订单(发货)
export function sendOut(data) {
  return request({
    url: '/order/ordShopOrderLogistics/sendOut',
    method: 'post',
    data,
  })
}
// 进货订单(修改地址提交)
export function editConsignee(data) {
  return request({
    url: '/order/ordShopOrderConsignee/edit',
    method: 'post',
    data,
  })
}
// 进货订单详情(待收货)
export function selectOrderLogistics(data) {
  return request({
    url: '/order/ordShopOrder/selectOrderLogistics',
    method: 'post',
    data,
  })
}
// 进货订单详情(收益分润)
export function profits(data) {
  return request({
    url: '/order/ordShopOrder/profits',
    method: 'post',
    data,
  })
}
// 进货订单（取货）
export function receiptGoods(data) {
  return request({
    url: '/order/ordShopOrderLogistics/receiptGoods',
    method: 'post',
    data,
  })
}
// 进货订单(系统时间)

export function sysTime(data) {
  return request({
    url: '/order/ordShopOrder/sysTime',
    method: 'post',
    data,
  })
}
// 进货订单(订单号check))

export function checkSoLogisticsNo(data) {
  return request({
    url: '/order/ordShopOrderLogistics/checkSoLogisticsNo',
    method: 'post',
    data,
  })
}
