<template>
  <div class="container">
    <el-table border :data="tablist" max-height="300">
      <!-- <el-table-column align="center" type="selection" width="55" /> -->
      <el-table-column align="center" label="序号" prop="id" show-overflow-tooltip width="50" />
      <el-table-column align="center" label="交易类型" prop="optType" width="80px">
        <template #default="scope">
          <div v-if="scope.row.optType == 0">汇入</div>
          <div v-if="scope.row.optType == 1">支出</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="交易金额" prop="logAmount" />
      <el-table-column align="center" label="汇入银行" prop="bankName" show-overflow-tooltip />
      <el-table-column align="center" label="银行账号" prop="bankNumber" show-overflow-tooltip width="200px" />
      <el-table-column align="center" label="银行户名" prop="title" show-overflow-tooltip width="200px" />
      <el-table-column align="center" label="收款时间" prop="processTime" width="230px" />
      <el-table-column align="center" label="汇款标签" prop="remBank" show-overflow-tooltip width="200px" />
      <el-table-column align="center" label="交易备注" prop="content" show-overflow-tooltip width="160px" />
      <el-table-column align="center" fixed="right" label="交易状态" prop="preStatus" width="140px">
        <template #default="scope">
          <div v-if="scope.row.preStatus == 1">已到款</div>
          <div v-if="scope.row.preStatus == 2">未到款</div>
          <div v-if="scope.row.preStatus == 3">已经取消</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="1" layout="total, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>

<script>
  import { statistics } from '@/api/mall/setting/stplabel'
  export default {
    props: {
      sound: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        total: 0,
        tablist: [],
        showlist: false,
        showtblist: null,
        form: {
          size: 10,
          current: 1,
          recordId: null,
        },
      }
    },
    watch: {
      sound(newValue, oldValue) {
        if (newValue != oldValue) {
          this.form.recordId = newValue.id
        }
      },
    },
    created() {
      this.form.recordId = this.sound.id
      this.uuUserSugarRecordLog()
    },
    methods: {
      closeshow() {
        this.showlist = false
      },
      showhide(row) {
        console.log('12')
        this.showtblist = row
        this.showlist = true
        console.log(this.showlist)
      },
      handleCurrentChange(e) {
        this.form.current = e
        this.uuUserSugarRecordLog()
      },
      handleSizeChange(e) {
        this.form.size = e
        this.uuUserSugarRecordLog()
      },
      uuUserSugarRecordLog() {
        statistics({ ...this.form }).then((res) => {
          console.log(res.data)
          this.total = res.data.total
          this.form.size = res.data.size
          this.form.current = res.data.current
          this.tablist = res.data.records
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
