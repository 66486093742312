import request from '@/utils/request'
/**
 * 统计模块
 * @param {*} data
 * @returns
 */
export function addCustomerOrder(data) {
  return request({
    url: `/mall/orderinfo/addCustomerOrder`,
    method: 'post',
    data,
  })
}



/**
 * 查询平台统计访问数据
 * @param {*} data
 * @returns
 */
export function indexVisittats(data) {
  return request({
    url: `/mall/index/indexVisittats`,
    method: 'post',
    data,
  })
}
/**
 * 查询平台统计注册数据
 * @param {*} data
 * @returns
 */
export function indexRegistStats(data) {
  return request({
    url: `/mall/index/indexRegistStats`,
    method: 'post',
    data,
  })
}
