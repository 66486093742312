import request from '@/utils/request'
// 企业管理列表
export function queryCompanyPage(data) {
  return request({
    url: '/admin/comSysOrg/queryCompanyPage',
    method: 'post',
    data,
  })
}
// 企业管理——新增企业
export function addCompany(data) {
  return request({
    url: '/admin/comBusiComp/addCompany',
    method: 'post',
    data,
  })
}
// 企业管理——详情（企业信息）
export function selectOrgCompDetails(data) {
  return request({
    url: '/admin/comBusiComp/selectOrgCompDetails',
    method: 'post',
    data,
  })
}
// 企业管理——详情（机构信息）
export function selectApplyOrgDetails(data) {
  return request({
    url: '/admin/comSysOrg/selectApplyOrgDetails',
    method: 'post',
    data,
  })
}
// 企业管理——企业禁用
export function editDisCompay(data) {
  return request({
    url: '/admin/comSysOrg/editDisCompay',
    method: 'post',
    data,
  })
}
// 企业审核列表
export function queryAuditCompanyPage(data) {
  return request({
    url: '/admin/comBusiCompTemp/queryAuditCompanyPage',
    method: 'post',
    data,
  })
}

// 企业审核（拒绝、通过）
export function passAuditCompany(data) {
  return request({
    url: '/admin/comBusiOrgApply/passAuditCompany',
    method: 'post',
    data,
  })
}
// 企业审核
export function compTempmedetails(data) {
  return request({
    url: '/admin/comBusiCompTemp/compTempmedetails',
    method: 'post',
    data,
  })
}
