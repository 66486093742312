<template>
  <div v-loading.sync="listLoading" style="padding: 10px">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div>
        <div style="display: flex">
          <h3 style="margin-right: 10px">当前收益（可提现）</h3>
          <h3 style="color: rgb(37, 115, 217)">￥{{ detail.profitAmount }}</h3>
        </div>
        <div style="display: flex; margin: 10px 0">
          <div style="margin-right: 20px">
            <span>累计收益：</span>
            <span style="color: rgb(37, 115, 217)">
              ￥{{ detail.totalProfitAmount }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>冻结收益：</span>
            <span style="color: rgb(37, 115, 217)">
              ￥{{ detail.frozenAmount }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>待结算：</span>
            <span style="color: rgb(37, 115, 217)">
              ￥{{ detail.settlingAmount }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>提现中：</span>
            <span style="color: rgb(37, 115, 217)">
              ￥{{ detail.withdrawingAmount }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>已提现：</span>
            <span style="color: rgb(37, 115, 217)">
              ￥{{ detail.withdrawedAmount }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="
            btnRolrs[4] &&
            btnRolrs[4]['searchBarButtons'] &&
            btnRolrs[4]['searchBarButtons'].length
          "
        >
          <div v-if="btnRolrs[4] && btnRolrs[4].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[4].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="变更类型" prop="businessName" />
        <el-table-column align="center" label="变更数值" prop="logAmount" />
        <el-table-column align="center" label="变更内容" prop="content" />
        <el-table-column align="center" label="变更后收益" prop="newAmount" />
        <el-table-column align="center" label="变更时间" prop="createTime" />
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import {
    getProfit,
    getProfitDetail,
    exportProfit,
  } from '@/api/mall/distUsers/userDetail'
  import { checkButton } from '@/utils/mixins'
  export default {
    name: 'Integral',
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        userId: 0,
        balanceAmount: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
        total: 0,
        accId: '',
        list: [],
        detail: {
          accId: 0,
          profitAmount: 0,
          frozenAmount: 0,
          totalProfitAmount: 0,
          settlingAmount: 0,
          totalOutAmount: 0,
          withdrawedAmount: 0,
          withdrawingAmount: 0,
        },
      }
    },
    created() {
      const { balanceAmount, accId } = this.$route.query
      this.balanceAmount = balanceAmount
      this.accId = accId
    },
    methods: {
      async fetchData(id) {
        this.listLoading = true
        this.userId = id
        const {
          data: { records, total },
        } = await getProfit({ userId: this.userId, ...this.queryForm })
        const { data: detail } = await getProfitDetail({ accId: this.accId })
        this.detail = detail
        this.total = total
        this.list = records
        this.listLoading = false
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        this.fetchData(this.userId)
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        this.fetchData(this.userId)
      },
      handleExport() {
        exportProfit({
          accId: this.accId,
          userId: this.userId,
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', new Date().getTime() + '.xlsx')
            link.click()
          })
          .catch(() => {
            this.$message.error('网络错误，请稍后重试！')
          })
      },
    },
  }
</script>
