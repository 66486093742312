var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "ym_swiper" },
      _vm._l(10, function (ym, index) {
        return _c(
          "div",
          { key: index, class: _vm.cur == index ? "active item" : "item" },
          [
            _c("div", { staticClass: "span" }, [_vm._v("热卖")]),
            _c("div", { staticClass: "span_txt" }, [_vm._v("热卖推荐")]),
          ]
        )
      }),
      0
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card_multion" }, [
      _c("div", { staticClass: "card-item" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }