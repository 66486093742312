/**
 * Copyright (C) 2018-2019
 * All rights reserved, Designed By
 * 注意：
 *
 * 购买后可获得全部源代码（禁止转卖、分享、上传到码云、github等开源平台）
 * 一经发现盗用、分享等行为，将追究法律责任，后果自负
 */
import request from '@/utils/request'

export function getPage(query) {
  return request({
    url: '/upms/configeditor/page',
    method: 'get',
    params: query,
  })
}

export function addObj(obj) {
  return request({
    url: '/upms/configeditor',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/upms/configeditor/' + id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/upms/configeditor/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/upms/configeditor',
    method: 'put',
    data: obj,
  })
}

export function getObj2() {
  return request({
    url: '/upms/configeditor',
    method: 'get',
  })
}
