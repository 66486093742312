import request from '@/utils/request'

export function getGoodsDetail(data) {
  return request({
    url: `/goods/proGoodsBasics/selectGoodsBase`,
    method: 'post',
    data,
  })
}

export function getGoodsArea(data) {
  return request({
    url: `/shop/proShopGoods/selectGoodsArea`,
    method: 'post',
    data,
  })
}

export function getGoodsImage(data) {
  return request({
    url: `/goods/proGoodsImage/selectGoodsImg`,
    method: 'post',
    data,
  })
}

export function getGoodsSpecs(data) {
  return request({
    url: `/goods/proGoodsBasicsSpecs/goodsSpecs`,
    method: 'post',
    data,
  })
}

export function getGoodsShelfAreas(data) {
  return request({
    url: `/goods/proGoodsBasicsArea/shelfAreas`,
    method: 'post',
    data,
  })
}

// 区域价格查询
export function getGoodsPrice(data) {
  return request({
    url: `/goods/proGoodsBasicsArea/areaPriceList`,
    method: 'post',
    data,
  })
}

export function getGoodsComment(data) {
  return request({
    url: `/goods/proGoodsEvaluation/selectEvaluation`,
    method: 'post',
    data,
  })
}

export function getGoodsCommentStatic(data) {
  return request({
    url: `/goods/proGoodsStatistic/selectGoodsStatistic`,
    method: 'post',
    data,
  })
}

export function getGoodsCommentCount(data) {
  return request({
    url: `/goods/proGoodsEvaLabRel/selectEvaluationLab`,
    method: 'post',
    data,
  })
}

export function getCommentInfo(data) {
  return request({
    url: `/goods/proGoodsEvaLabRel/selectEvalLabInfoList`,
    method: 'post',
    data,
  })
}
export function updateCommentStatus(data) {
  return request({
    url: `/goods/proGoodsEvaluation/update`,
    method: 'post',
    data,
  })
}

export function getGoodsOrders(data) {
  return request({
    url: `/order/ordOrder/selectOrderPage`,
    method: 'post',
    data,
  })
}

export function getGoodsLog(data) {
  return request({
    url: `/goods/proGoodsLog/queryPage`,
    method: 'post',
    data,
  })
}

export function modifyPrice(data) {
  return request({
    url: `/goods/proGoodsBasicsArea/modifyPrice`,
    method: 'post',
    data,
  })
}
