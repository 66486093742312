<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <el-form label-width="5em">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="店铺:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否支付:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单编号:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单信息:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单类型:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="来源应用:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="配送方式:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="付款方式:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="来源:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发货类型:">
              <el-select v-model="value" class="m-b w100s" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary">搜索</el-button>
            <el-button type="primary">清空</el-button>
          </el-col>
        </el-row>
      </el-form>
    </vab-query-form>
    <el-table border :data="data" stripe>
      <el-table-column align="center" label="店铺" />
      <el-table-column align="center" label="订单信息" />
      <el-table-column align="center" label="时间/订单编号" />
      <el-table-column align="center" label="配送方式" />
      <el-table-column align="center" label="状态" />
      <el-table-column align="center" label="订单金额" />
      <el-table-column align="center" label="付款方式" />
      <el-table-column align="center" label="来源" />
      <el-table-column align="center" label="发货类型" />
      <el-table-column align="center" label="操作" />
    </el-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        data: [],
        options: [
          {
            value: '选项1',
            label: 'Apple正品-上海官方旗舰店',
          },
          {
            value: '选项2',
            label: '双皮奶',
          },
        ],
        value: '选项1',
      }
    },
  }
</script>

<style lang="scss" scoped>
  .w100s {
    width: 100%;
  }
</style>
