<template>
  <div style="height: 79vh; padding: 10px">
    <div style="display: flex; flex-wrap: wrap;">
      <div class="title-item">
        <span>用户ID</span>
        <el-input
          v-model.trim="formInline.id"
          class="title-input"
          placeholder="请输入用户ID"
        />
      </div>
      <div class="title-item">
        <span>用户名称</span>
        <el-input
          v-model.trim="formInline.nickname"
          class="title-input"
          placeholder="请输入用户名称"
        />
      </div>
      <div class="title-item">
        <span>联系方式</span>
        <el-input
          v-model.trim="formInline.phone"
          class="title-input"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="title-item">
        <span>用户来源</span>
        <el-select
          v-model.trim="formInline.userSource"
          class="title-input"
          placeholder="请选择用户来源"
        >
          <el-option
            v-for="(item, index) in userSource"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </div>
      <div class="title-item">
        <span>用户身份</span>
        <el-select
          v-model="formInline.memberLevel"
          class="title-input"
          placeholder="请选择用户身份"
        >
          <el-option
            v-for="(item, index) in userIdentity"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </div>
      <div class="title-item">
        <span>员工身份</span>
        <el-select
          v-model="formInline.userType"
          class="title-input"
          placeholder="请选择员工身份"
        >
          <el-option
            v-for="(item, index) in personnelIdentity"
            :key="index"
            :label="item.dictValue"
            :value="item.dictKey"
          />
        </el-select>
      </div>
      <div class="title-item">
        <span>注册时间</span>
        <el-date-picker
          v-model="datePicker"
          align="center"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="dateChange"
        />
      </div>
      <div class="title-item">
        <el-button icon="el-icon-search" type="warning" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh-left"
          type="primary"
          @click="handleReset"
        >
          重置
        </el-button>
      </div>
    </div>
    <el-table border :data="userList" :max-height="600" style="width: 100%">
      <el-table-column align="center" label="用户ID" prop="userId" />
      <el-table-column align="center" label="用户名称">
        <template #default="{ row }">
          <div>{{ !row.userInfo ? '' : row.userInfo.nickName }}</div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="用户来源" prop="userSource">
        <template #default="{ row }">
          <div>{{ row.userSource | 'getSource' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="联系方式" prop="phone" />
      <el-table-column align="center" label="用户身份">
        <template #default="{ row }">
          <div>{{ row.memberLevel | 'getLevel' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="员工身份" prop="userType" />
      <el-table-column align="center" label="推荐ID" prop="parentId" />
      <el-table-column align="center" label="积分" prop="integralAccount" />
      <el-table-column
        align="center"
        label="累计佣金金额（元）"
        prop="commissionTotal"
      />
      <el-table-column
        align="center"
        label="已提现佣金金额（元）"
        prop="commissionWithdrawal"
      />
      <el-table-column align="center" label="注册时间" prop="createTime" />
      <el-table-column align="center" label="操作" width="220px">
        <template #default="{ row }">
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <div
              v-for="item in row.btns"
              v-show="item.isShow && item.buttonStatus == 0"
              :key="item.id"
            >
              <el-dropdown
                v-if="item.children && item.children.length"
                style="margin: 0 5px"
                @command="
                  (index) => {
                    handleClickBtn(item.buttonUrl, index, item.children, row)
                  }
                "
              >
                <el-button :type="item.buttonCss">
                  {{ item.buttonName }}
                  <vab-icon :icon="item.buttonIcon || ''" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item2 in item.children"
                    :key="item2.id"
                    :command="item2.id"
                  >
                    {{ item2.buttonName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                size="mini"
                style="margin: 0 5px"
                :type="item.buttonCss"
                @click="handleClickBtn(item.buttonUrl, row)"
              >
                <vab-icon :icon="item.buttonIcon || ''" />
                {{ item.buttonName }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.current"
      :layout="layout"
      :page-size="queryForm.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <coupon ref="coupon" @fetch="fetchData" />
    <password ref="password" @fetch="fetchData" />
    <profit ref="profit" @fetch="fetchData" />
  </div>
</template>

<script>
  import { getUserList, disableUser } from '@/api/mall/distUsers/list'
  import { selectSysDict } from '@/api/mall/common'
  import Coupon from './components/Coupon.vue'
  import Password from './components/Password.vue'
  import Profit from './components/Profit.vue'
  import { checkButton } from '@/utils/mixins'

  export default {
    name: 'UserList',
    components: {
      Coupon,
      Password,
      Profit,
    },
    filters: {
      getSource(val) {
        let str = ''
        switch (val) {
          case 'ios':
            str = '苹果IOS'
            break
          case 'android':
            str = '安卓'
            break
          case 'wxapplet':
            str = '微信小程序'
            break
          case 'pc':
            str = '电脑端'
            break
          case 'h5':
            str = '微信公众号H5'
            break
        }
        return str
      },
      getLevel(val) {
        let str = ''
        switch (val) {
          case 1:
            str = '游侠'
            break
          case 20:
            str = '小淘侠'
            break
          case 21:
            str = '大淘侠'
            break
          case 22:
            str = '靓淘侠'
            break
          case 23:
            str = '游侠'
            break
        }
        return str
      },
    },
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        userList: [],
        datePicker: [],
        userSource: [],
        userIdentity: [],
        personnelIdentity: [],
        isSearch: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        formInline: {
          id: '',
          nickname: '',
          userSource: '',
          phone: '',
          memberLevel: '',
          userType: '',
          beginTime: '',
          endTime: '',
        },
        total: 0,
        queryForm: {
          current: 1,
          size: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleReset() {
        this.formInline = {
          id: '',
          nickname: '',
          userSource: '',
          phone: '',
          memberLevel: '',
          userType: '',
          beginTime: '',
          endTime: '',
        }
        this.datePicker = []
        this.handleSearch()
      },
      disableUser(row) {
        this.$confirm('此操作将禁用此用户，是否继续？', '提示', {
          confirmButtonText: '禁用',
          type: 'warning',
        }).then(() => {
          const { id } = row
          disableUser({ userId: id }).then(() => {
            this.fetchData(this.formInline)
            this.$message.success('禁用成功！')
          })
        })
      },
      openDialog(e, btns, row) {
        const obj = btns.find((item) => item.id == e)
        obj && this.$refs[obj.buttonUrl].show(row)
      },
      async fetchData(options = {}) {
        this.listLoading = true
        const {
          data: { records: userList, total },
        } = await getUserList({ ...this.queryForm, ...options })
        this.handleAddTableButtonRole(userList, {})
        const dicData = await selectSysDict({
          dictTypeList: ['userFrom', 'userIdentity', 'personnelIdentity'],
        })
        this.userSource = dicData.data[0].dictInfoVoList
        this.userIdentity = dicData.data[1].dictInfoVoList
        this.personnelIdentity = dicData.data[2].dictInfoVoList
        this.userList = userList
        this.total = total
      },
      async handleSearch() {
        this.queryForm.current = 1
        await this.fetchData({ ...this.formInline }, false)
        let temp = false
        Object.keys(this.formInline).forEach((key) => {
          this.formInline[key] && (temp = true)
        })
        this.isSearch = temp
      },
      openDetail(row) {
        this.$router.push({
          path: '/user/detail',
          query: row,
        })
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData({ ...data }, false)
      },
      dateChange(date) {
        if (date && date.length) {
          this.formInline.beginTime = date[0]
          this.formInline.endTime = date[1]
        } else {
          this.formInline.beginTime = ''
          this.formInline.endTime = ''
        }
      },
    },
  }
</script>

<style scoped>
  .title-input {
    width: 220px;
    margin-left: 5px;
  }

  .title-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .title-item span {
    width: 100px;
    text-align: center;
  }
</style>
