import request from '@/utils/request'

export function getTagList(data) {
  return request({
    url: `/goods/proGoodsLabel/queryPage`,
    method: 'post',
    data,
  })
}

export function getTagSubList(data) {
  return request({
    url: `/goods/proGoodsLabel/subList`,
    method: 'post',
    data,
  })
}

export function addGoodsTag(data) {
  return request({
    url: `/goods/proGoodsLabel/add`,
    method: 'post',
    data,
  })
}

export function editGoodsTag(data) {
  return request({
    url: `/goods/proGoodsLabel/edit`,
    method: 'post',
    data,
  })
}

export function deleteGoodsTag(data) {
  return request({
    url: `/goods/proGoodsLabel/delete`,
    method: 'post',
    data,
  })
}

export function disableGoodsTag(data) {
  return request({
    url: `/goods/proGoodsLabel/disable`,
    method: 'post',
    data,
  })
}

export function enableGoodsTag(data) {
  return request({
    url: `/goods/proGoodsLabel/recovery`,
    method: 'post',
    data,
  })
}
