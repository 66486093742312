import request from '@/utils/request'

export function getGoodsList(data) {
  return request({
    url: '/goods/wms/page',
    method: 'post',
    data,
  })
}

export function getAllBrand(data) {
  return request({
    url: '/goods/proGoodsBrand/list',
    method: 'post',
    data,
  })
}

export function deleteGoods(data) {
  return request({
    url: '/goods/wms/delete',
    method: 'post',
    data,
  })
}

export function deleteGoodsList(data) {
  return request({
    url: '/goods/wms/batchDelete',
    method: 'post',
    data,
  })
}

export async function recoveryGoods(data) {
  return request({
    url: '/goods/wms/recovery',
    method: 'post',
    data,
  })
}

export async function recoveryGoodsList(data) {
  return request({
    url: '/goods/wms/batchRecovery',
    method: 'post',
    data,
  })
}
// 导出
export function exportGoods(data) {
  return request({
    url: '/goods/wms/export',
    method: 'post',
    responseType: 'blob',
    data,
  })
}
