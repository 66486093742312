var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.regisetPhone()
          },
        },
      },
      [_vm._v("注 册")]
    ),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.unregisetPhone()
          },
        },
      },
      [_vm._v("注 销")]
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.calledPhone,
          expression: "calledPhone",
        },
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.calledPhone },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.calledPhone = $event.target.value
        },
      },
    }),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.callPhone()
          },
        },
      },
      [_vm._v("拨 打")]
    ),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.hangupPhone()
          },
        },
      },
      [_vm._v("挂 机")]
    ),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.startWeb()
          },
        },
      },
      [_vm._v("链接webSocket")]
    ),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.stopWeb()
          },
        },
      },
      [_vm._v("断开webSocket")]
    ),
    _c("audio", { attrs: { id: "remoteAudio", autoPlay: "" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }