<template>
  <div class="mt20">
    <el-container style="width: 100%">
      <!-- container内容容器-->
      <div ref="contantRef" class="contantAuthorizeUrl">
        <!-- 查询 -->
        <div class="mb10">
          <el-form
            ref="refGeneral"
            :inline="true"
            :model="listQuery"
            @submit.native.prevent
          >
            <el-form-item>
              <el-input
                v-model="listQuery.postCode"
                class="filter-input"
                clearable
                placeholder="岗位编码"
                type="text"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="listQuery.postType"
                class="filter-input"
                clearable
                placeholder="岗位类型"
              >
                <el-option
                  v-for="item in postTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="handleQuery"
              >
                查询
              </el-button>
              <el-button
                icon="el-icon-refresh-right"
                type="primary"
                @click="handleReset"
              >
                重置
              </el-button>
            </el-form-item>
            <el-form-item style="float: right">
              <!-- <el-button
                icon="el-icon-plus"
                type="primary"
                @click="handleCreat"
              >
                新增
              </el-button> -->
              <el-button
                v-for="item in searchButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                :type="item.buttonCss"
                @click.native="handleClickBtn(item)"
              >
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
            </el-form-item>
          </el-form>
          <div class="filter-btn"></div>
        </div>
        <!-- table列表 -->
        <el-table
          ref="orgTableDataList"
          v-loading="listLoading"
          border
          :data="list"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            align="center"
            show-overflow-tooltip
            type="selection"
            width="55"
          />
          <el-table-column
            align="center"
            label="岗位上下级名称"
            prop="postName"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="岗位编码"
            prop="postCode"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="岗位类型"
            prop="postType"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <div>
                {{ row.postType == 1 ? '主岗位 ' : '副岗位' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="状态"
            prop="status"
            show-overflow-tooltip
          >
            <template #default="{ row }">
              <el-tag :type="row.status == 0 ? 'danger' : 'success'">
                {{ row.status == 0 ? '隐藏 ' : '显示' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="岗位描述"
            prop="postDescription"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="创建时间"
            prop="createTime"
            show-overflow-tooltip
            sortable
          >
            <template #default="{ row }">
              <div>
                {{ row.createTime | formatDate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="operationShow" align="center" label="操作">
            <template #default="{ row }">
              <el-button
                v-for="item in listButton"
                :key="item.id"
                :plain="item.cssType && item.cssType == 'plain' ? true : false"
                :type="item.buttonCss"
                @click.native="handleClickBtn(item, row)"
              >
                <span v-if="!item.buttonIcon"></span>
                <vab-icon v-else :icon="item.buttonIcon" />
                {{ item.buttonName }}
              </el-button>
              <!-- <el-button
                icon="el-icon-edit"
                size="small"
                type="text"
                @click="handleEdit(row)"
              >
                编辑
              </el-button>
             -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-container>
    <!-- 新增(编辑) -->
    <el-dialog
      v-if="addVisible"
      class="addDialog"
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="addVisible"
      @close="close"
    >
      <el-form
        ref="addForm"
        label-width="80px"
        :model="addForm"
        :rules="addFormRules"
      >
        <el-form-item class="postPare" label="所属机构" prop="orgId">
          <el-cascader
            ref="cascaderMallCatergory"
            v-model="addForm.orgId"
            clearable
            :disabled="dialogTitle === '编辑'"
            :options="dataSourceLists"
            :props="props"
            style="width: 90%"
            @change="handelCascader"
          />
        </el-form-item>
        <el-form-item label="岗位名称" prop="postCode">
          <el-select
            v-model="addForm.postCode"
            clearable
            placeholder="请选择职务"
            style="width: 100%"
          >
            <el-option
              v-for="item in postList"
              :key="item.postCode"
              :label="item.postName"
              :value="item.postCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="岗位类型" prop="postType" required>
          <el-radio-group v-model="addForm.postType">
            <el-radio :label="1">主岗位</el-radio>
            <el-radio :label="2">副岗位</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态" prop="status" required>
          <el-radio-group v-model="addForm.status">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="岗位描述" prop="postDescription">
          <el-input
            v-model.trim="addForm.postDescription"
            autocomplete="off"
            clearable
            placeholder="请输入"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogTitle === '新增' ? createData() : updateData()"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    userPost,
    postOptionTree,
    addPost,
    updatePost,
  } from '@/api/mall/role/userManage'
  import { selectOrgPostList } from '@/api/mall/oaSys/employees'
  import { orgTree } from '@/api/mall/role/orgManage'
  import { selectRoleMenuButtonList } from '@/api/mall/common'
  export default {
    name: 'UserComponent',
    // eslint-disable-next-line vue/require-prop-types
    props: ['msg'],
    data() {
      return {
        operationShow: false,
        searchButton: [],
        listButton: [],
        postList: [],
        props: {
          checkStrictly: true,
          label: 'orgName',
          value: 'id',
        },
        dataSourceLists: [],
        parentName: '',
        parentIdOptions: [],
        current: 1,
        size: 10,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        listLoading: false,
        listQuery: {
          userId: undefined,
          postCode: undefined,
          postType: undefined,
          status: undefined,
          postDescription: undefined,
        },
        states: '1',
        menuName: '',
        postsDsc: '',
        postsTypes: '',
        postsCodes: '',
        list: [],
        orgUserName: '',
        addForm: {
          postType: 1,
          status: 1,
          postCode: '',
        },
        addVisible: false,
        multipleSelection: [],
        dialogTitle: '',
        roleSelectName: '',
        postTypeList: [
          {
            label: '主岗位',
            value: '1',
          },
          {
            label: '副岗位',
            value: '2',
          },
        ],
        stateSelect: [
          {
            label: '显示',
            value: '1',
          },
          {
            label: '隐藏',
            value: '0',
          },
        ],
        searchShow: false,
        stateSelectName: '',
        pinkName: '',
        rolesId: '',
        mobilePhones: '',
        addFormRules: {
          postCode: [
            {
              required: true,
              message: '请输入岗位名称！',
              trigger: ['blur', 'change'],
            },
          ],
          orgId: [
            {
              required: true,
              message: '请选择机构',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    watch: {
      msg(oldValue, newValue) {
        console.log(oldValue, newValue)
        this.getList()
      },
    },
    created() {
      if (this.msg[0]) {
        this.getList()
      }
      this.selectRoleMenuButtonList()
    },
    methods: {
      // 按钮点击
      handleClickBtn(item, row) {
        if (item.buttonUrl) {
          this[item.buttonUrl](row)
        }
      },
      // 按钮权限
      selectRoleMenuButtonList() {
        selectRoleMenuButtonList({
          roleIdStrList: this.$store.getters['acl/roleList'].length ===0?"":this.$store.getters['acl/roleList'],
          menuId: this.$route.meta.id,
        })
          .then((res) => {
            if (res.data) {
              this.searchButton = res.data.filter(function (item) {
                return item.buttonType == 1 && item.modelType == 2
              })
              this.listButton = res.data.filter(function (item) {
                return item.buttonType == 2 && item.modelType == 2
              })
              if (this.listButton.length > 0) {
                this.operationShow = true
              } else {
                this.operationShow = false
              }
            }
          })
          .catch(() => {})
      },
      // 所属机构
      handelCascader(val) {
        this.addForm.postCode = ''
        const orgId = this.msg[0].orgId.split(',')
        const vals = val[val.length - 1]
        if (orgId.includes(vals) == false) {
          this.ME('不能选择此机构')
          this.addForm.orgId = []
          this.postList = []
        }
        // 岗位(职务)
        selectOrgPostList({ orgIdArray: this.menuChange(val) })
          .then((res) => {
            this.postList = res.data
          })
          .catch(() => {})
      },
      // 所属机构
      orgTree() {
        orgTree({
          current: this.current,
          size: this.size,
        })
          .then((res) => {
            if (res.data) {
              this.dataSourceLists = res.data.records
            }
          })
          .catch(() => {})
      },

      // 添加
      createData() {
        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            const parms = {
              userId: this.msg[0].id,
              postCode: this.addForm.postCode,
              orgId: this.menuChange(this.addForm.orgId),
              postType: this.addForm.postType,
              status: this.addForm.status,
              postDescription: this.addForm.postDescription,
            }
            addPost(parms)
              .then(() => {
                this.MS('新增成功')
                this.close()
                this.getList()
              })
              .catch(() => {})
          }
        })
      },
      // 方法（获取数组最后一位）
      menuChange(dataSourceLists) {
        if (typeof dataSourceLists === 'string') {
          return dataSourceLists
        } else {
          if (dataSourceLists && dataSourceLists.length) {
            const arrList = dataSourceLists[dataSourceLists.length - 1]
            return arrList
          } else {
            return ''
          }
        }
      },
      // 编辑
      updateData() {
        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            const parms = {
              id: this.rolesId,
              userId: this.msg[0].id,
              postCode: this.addForm.postCode,
              orgId: this.menuChange(this.addForm.orgId),
              postType: this.addForm.postType,
              status: this.addForm.status,
              postDescription: this.addForm.postDescription,
            }
            updatePost(parms)
              .then(() => {
                this.MS('修改成功')
                this.close()
                this.getList()
              })
              .catch(() => {})
          }
        })
      },
      // 岗位tree
      async postOptionTree() {
        await postOptionTree()
          .then((res) => {
            this.parentIdOptions = res.data
          })
          .catch(() => {})
      },
      // 根据用户查询岗位列表
      getList() {
        const parms = {
          userId: this.msg && this.msg.length > 0 ? this.msg[0].id : '1',
          orgId: this.msg && this.msg.length > 0 ? this.msg[0].orgId : '1',
          postCode: this.listQuery.postCode,
          postType: this.listQuery.postType,
        }
        userPost({ ...parms })
          .then((res) => {
            this.list = res.data
          })
          .catch(() => {})
        postOptionTree().then((res) => {
          console.log(res)
          // this.parentIdOptions = res.data
        })
      },
      // 关闭
      close() {
        this.$refs['addForm'].resetFields()
        this.addForm = {}
        this.addForm = this.$options.data().addForm
        this.addVisible = false
      },

      // 编辑
      handleEdit(row) {
        this.orgTree()
        this.dialogTitle = '编辑'
        this.rolesId = row.id
        this.addForm = Object.assign({}, row)
        this.addVisible = true
        // this.$nextTick(() => {
        //   console.log(this.$refs.treeParentId)
        //   console.log(this.$refs.treeParentId.getNode(this.addForm.postCode))
        //   if (this.addForm.postCode === 0) {
        //     this.parentName = '请选择'
        //   } else {
        //     this.parentName = this.$refs.treeParentId.getNode(
        //       this.addForm.postCode
        //     ).label
        //   }
        // })
      },
      // 添加
      handleCreat() {
        this.orgTree()
        if (this.msg.length === 0) return this.ME('请勾选用户')
        this.parentName = undefined
        this.dialogTitle = '新增'
        this.addVisible = true
        this.$nextTick(() => {
          this.$refs['addForm'].clearValidate()
        })
      },
      // 删除
      handleDelete(row) {
        if (row) {
          console.log(row.id)
        } else {
          if (this.multipleSelection.length === 0) {
            this.ME('请勾选需要删除的记录')
            return
          }
          this.$confirm('是否要删除此行?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              //   this.multipleSelection.map((item) => item.id).join(','),
            })
            .catch(() => {})
        }
      },
      // 表格操作
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      // 重置
      handleReset() {
        this.current = 1
        this.listQuery = {}
        this.getList()
      },
      // 查询
      handleQuery() {
        this.getList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .filter-btn {
    float: right;
  }
  .mobilePhone {
    .el-select .el-input {
      width: 130px;
    }
    .input-with-select .el-input-group__prepend {
      background: #fff;
    }
  }
  .asideAuthorizeUrl {
    width: 160px;
    height: 600px;
    overflow: scroll;
    border: 1px solid #c0d4f0;
    .el-table {
      th.is-leaf {
        background: #fff;
      }
    }
    .el-input {
      width: 130px;
      padding: 10px;
    }
    .aside-table td,
    .aside-table th.is-leaf {
      border: none;
    }
    .aside-table th {
      .cell {
        font-weight: 700 !important;
        color: #909399 !important;
      }
    }
    .aside-table::before {
      width: 0;
    }
  }
  .clickLine {
    width: 8px;
    height: 900px;
    margin-right: 5px;
    background-color: #c0d4f0;
    .clickLineLeft {
      position: relative;
      top: 50%;
      left: -3px;
      color: #97a8be;
      cursor: pointer;
    }
    .clickLineRight {
      position: relative;
      top: 50%;
      left: -5px;
      color: #97a8be;
      cursor: pointer;
    }
  }
  .filter-input {
    width: 140px;
  }
  .addDialog {
    .el-form-item__label {
      width: 90px;
      font-size: 14px;
      font-weight: 700;
    }
    .el-input {
      width: 90%;
    }
    .el-textarea {
      width: 90%;
    }
    .el-form-item {
      margin-bottom: 22px;
    }
  }
  .contantAuthorizeUrl {
    width: 100%;
    // padding: 0px 10px;
  }
  .transfer-title {
    margin-top: 0px;
  }
</style>
