import request from '@/utils/request'
// 仓库管理列表
export function queryDepotListForPage(data) {
  return request({
    url: `/ware/wmsDepot/queryDepotListForPage`,
    method: 'post',
    data,
  })
}
// 添加仓库
export function add(data) {
  return request({
    url: `/ware/wmsDepot/add`,
    method: 'post',
    data,
  })
}
// 编辑仓库
export function edit(data) {
  return request({
    url: `/ware/wmsDepot/edit`,
    method: 'post',
    data,
  })
}

// 查询仓库负责人
export function queryListDepotForPage(data) {
  return request({
    url: `/admin/comSysUser/queryListDepotForPage`,
    method: 'post',
    data,
  })
}
// 是否默认
export function editDefault(data) {
  return request({
    url: `/ware/wmsDepot/editDefault`,
    method: 'post',
    data,
  })
}
// 删除、禁用、恢复正常
export function editDelete(data) {
  return request({
    url: `/ware/wmsDepot/editDelete`,
    method: 'post',
    data,
  })
}
