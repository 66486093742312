import request from '@/utils/request'
// 签约师列表
export function getSignList(data) {
  return request({
    url: '/org/comBusiSignLecturerBase/queryPage',
    method: 'post',
    data,
  })
}
// 签约师列表（添加）
export function add(data) {
  return request({
    url: '/org/comBusiSignLecturerBase/add',
    method: 'post',
    data,
  })
}
// 签约师列表（编辑）
export function edit(data) {
  return request({
    url: '/org/comBusiSignLecturerBase/edit',
    method: 'post',
    data,
  })
}
// 签约师列表（详情）
export function details(data) {
  return request({
    url: '/org/comBusiSignLecturerBase/details',
    method: 'post',
    data,
  })
}
// 签约师列表（详情-签约授权机构）
export function detailsOrg(data) {
  return request({
    url: '/org/comBusiSignLecturerGrant/queryGrantListForPage',
    method: 'post',
    data,
  })
}
// 签约师列表（详情-签约授权机构-签约门店）
export function detailsOrgDoor(data) {
  return request({
    url: '/org/comBusiSignLecturerTalk/queryGrantShopListForPage',
    method: 'post',
    data,
  })
}
// 签约师列表（添加--用户列表）
export function queryPage(data) {
  return request({
    url: '/user/uUser/queryPage',
    method: 'post',
    data,
  })
}
// 签约师列表（添加-提交）
export function addSign(data) {
  return request({
    url: '/org/comBusiSignLecturerBase/add',
    method: 'post',
    data,
  })
}
// 签约师列表（编辑-提交）
export function editSign(data) {
  return request({
    url: '/org/comBusiSignLecturerBase/edit',
    method: 'post',
    data,
  })
}
