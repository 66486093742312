var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "79vh" } },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 5 } }, [
            _c("div", { staticStyle: { height: "200px", padding: "10px" } }, [
              _c("h3", { staticStyle: { color: "rgb(0, 130, 255)" } }, [
                _vm._v("用户详情"),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    background: "#eee",
                    "border-radius": "20px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { margin: "0 10px" } },
                    [
                      _c("el-avatar", {
                        attrs: {
                          icon: "el-icon-user-solid",
                          size: 50,
                          src: _vm.userDetail.avatar,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("h3", [_vm._v(_vm._s(_vm.userDetail.nickname))]),
                    _c("h3", [_vm._v("ID: " + _vm._s(_vm.userDetail.id))]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                    padding: "10px",
                    "margin-top": "10px",
                    "background-color": "#eee",
                    "border-radius": "12px",
                  },
                },
                [
                  _c("div", [
                    _c("span", [_vm._v("上次登录时间：")]),
                    _c("span", [_vm._v(_vm._s(_vm.userDetail.lastLoginTime))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "switch-container" },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "active-value": 1,
                          "inactive-value": 0,
                          value: _vm.userDetail.isStatus,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleChangeStatus.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "msgContainer",
                  staticStyle: {
                    padding: "20px 0 0 0",
                    "margin-top": "20px",
                    "background-color": "#eee",
                    "border-radius": "15px",
                  },
                },
                [
                  _c("ul", [
                    _c("li", [
                      _c("span", [_vm._v("用户姓名：")]),
                      _c("span", [_vm._v(_vm._s(_vm.userDetail.nickname))]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("身份证号码：")]),
                      _c("span", [_vm._v(_vm._s(_vm.userDetail.cardId))]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("用户生日：")]),
                      _c("span", [_vm._v(_vm._s(_vm.userDetail.birthday))]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("手机号码：")]),
                      _c("span", [_vm._v(_vm._s(_vm.userDetail.phone))]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("邮箱：")]),
                      _c("span", [_vm._v(_vm._s(_vm.userDetail.email))]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("用户来源：")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("getSource")(_vm.userDetail.userSource))
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("推荐ID：")]),
                      _c("span", [_vm._v(_vm._s(_vm.userDetail.parentId))]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("注册时间：")]),
                      _c("span", [_vm._v(_vm._s(_vm.userDetail.registerTime))]),
                    ]),
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "align-items": "center",
                            width: "200px",
                          },
                        },
                        [
                          _c("div", {
                            ref: "qrCodeUrl",
                            staticClass: "qrcode",
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                color: "#fff",
                              },
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.downloadQR },
                            },
                            [_vm._v("下载二维码")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 19 } }, [
            _c(
              "div",
              { staticStyle: { "margin-top": "50px" } },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { stretch: true },
                    on: { "tab-click": _vm.tabClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "积分", name: "1" } },
                      [_c("integral", { ref: "integral" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "金豆", name: "2" } },
                      [_c("goldenBean", { ref: "goldenBean" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "余额", name: "3" } },
                      [_c("balance", { ref: "balance" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "收益", name: "4" } },
                      [_c("profit", { ref: "profit" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "优惠券", name: "5" } },
                      [_c("coupon", { ref: "coupon" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "收货地址", name: "6" } },
                      [_c("receiving", { ref: "receiving" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "订单", name: "7" } },
                      [_c("order", { ref: "order" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "发票", name: "8" } },
                      [_c("invoice", { ref: "invoice" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "扩展团队", name: "9" } },
                      [_c("equipa", { ref: "equipa" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "推荐团队", name: "10" } },
                      [_c("recommended", { ref: "recommended" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "收藏", name: "11" } },
                      [_c("collect", { ref: "collect" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "足迹", name: "12" } },
                      [_c("browse", { ref: "browse" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "搜索", name: "13" } },
                      [_c("query", { ref: "query" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "机构列表", name: "14" } },
                      [_c("shop-positions", { ref: "shopPositions" })],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }