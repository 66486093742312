import request from '@/utils/request'
// 区运营中心-数据信息-详情
export function getStoresTongJi(data) {
  return request({
    url: '/shop/stShop/getStoresTongJi',
    method: 'post',
    data,
  })
}
// 区运营中心-数据信息-门店
export function queryOrgShopPage(data) {
  return request({
    url: '/shop/stShop/queryOrgShopPage',
    method: 'post',
    data,
  })
}
// 区运营中心-数据信息-门店-查看详情
export function queryOrgShopDetail(data) {
  return request({
    url: '/shop/stShop/queryOrgShopDetail',
    method: 'post',
    data,
  })
}
// 区运营中心-数据信息-用户-统计
export function queryShopCustmMemberSum(data) {
  return request({
    url: '/shop/stShopCustm/queryShopCustmMemberSum',
    method: 'post',
    data,
  })
}
// 区运营中心-数据信息-用户列表
export function queryShopCustmListForPage(data) {
  return request({
    url: '/shop/stShopCustm/queryShopCustmListForPage',
    method: 'post',
    data,
  })
}
// 机构管理_机构列表_数据信息-概况(头像)
export function common(data) {
  return request({
    url: '/org/data/common',
    method: 'post',
    data,
  })
}
// 机构管理_机构列表_数据信息-员工列表
export function queryIncUserOrgListForPage(data) {
  return request({
    url: '/user/comIncUserBase/queryIncUserOrgListForPage',
    method: 'post',
    data,
  })
}
