<template>
  <div>
    <!-- <el-dialog
      title="选择优惠券"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <div style="height: 75vh; padding: 10px">
        <el-row>
          <el-col :span="11">
            <el-card
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 70vh;
              "
            >
              <div
                slot="header"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                可选优惠券
              </div>
              <div style="display: flex; flex: 1; flex-direction: column">
                <div>
                  <el-input
                    v-model.lazy="formInline.couponName"
                    placeholder="搜索优惠券"
                    @change="handleSearch"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </div>
                <ul
                  v-infinite-scroll="load"
                  :infinite-scroll-disabled="disabledScroll"
                  style="
                    height: 55vh;
                    padding: 0;
                    margin-top: 10px;
                    overflow-y: auto;
                  "
                >
                  <li
                    v-for="(item, index) in list"
                    :key="index"
                    :class="{ active: activeIndex === index }"
                    style="margin: 5px 0"
                    @click="handleCouponClick(item, index)"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        height: 100px;
                        padding: 0 20px;
                        cursor: pointer;
                        border: 2px dashed #eee;
                      "
                    >
                      <div>
                        <div>
                          <h2>￥{{ item.faceValue }}</h2>
                        </div>
                        <div>{{ item.productQuantity | initQuantity }}</div>
                      </div>
                      <div>
                        <h4>{{ item.couponName }}</h4>
                        <span>{{ item.usableEndTime }}到期</span>
                      </div>
                    </div>
                  </li>
                  <li
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p v-if="loading">加载中...</p>
                  </li>
                  <li
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p v-if="noMore">没有更多了</p>
                  </li>
                </ul>
              </div>
            </el-card>
          </el-col>
          <el-col :span="2">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                height: 70vh;
              "
            >
              <el-button @click="addItem">添加</el-button>
            </div>
          </el-col>
          <el-col :span="11">
            <el-card
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 70vh;
              "
            >
              <div
                slot="header"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                已选优惠券
              </div>
              <div style="display: flex; flex: 1; flex-direction: column">
                <ul style="height: 60vh; padding: 0; overflow-y: auto">
                  <li
                    v-for="(item, index) in checkedList"
                    :key="index"
                    style="position: relative; margin: 5px 0"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        height: 100px;
                        padding: 0 20px;
                        border: 2px dashed #eee;
                      "
                    >
                      <div>
                        <div>
                          <h2>￥{{ item.faceValue }}</h2>
                        </div>
                        <div>{{ item.productQuantity | initQuantity }}</div>
                      </div>
                      <div>
                        <h4>{{ item.couponName }}</h4>
                        <span>{{ item.usableEndTime }}到期</span>
                      </div>
                    </div>
                    <div style="position: absolute; top: 74px; right: 21px">
                      数量：
                      <input
                        v-if="!item.count"
                        v-model.lazy="item.count"
                        style="width: 100px"
                        type="number"
                        @change="checkInput($event, item)"
                      />
                      <span v-else>
                        <span>{{ item.count }}</span>
                        <span
                          style="
                            display: inline-block;
                            margin-left: 5px;
                            color: red;
                            cursor: pointer;
                          "
                          @click="item.count = false"
                        >
                          x
                        </span>
                      </span>
                    </div>
                    <div class="close-badge" @click="handleDelete(index)">
                      x
                    </div>
                  </li>
                </ul>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
  // import { getCoupon, addCoupon } from '@/api/mall/users/list'
  // export default {
  //   filters: {
  //     initQuantity(val) {
  //       let str = ''
  //       if (val != -1) {
  //         str = `${val}元以上可以使用`
  //       } else {
  //         str = '任意金额可以使用'
  //       }
  //       return str
  //     },
  //   },
  //   data() {
  //     return {
  //       dialogFormVisible: false,
  //       dialogVisible: false,
  //       activeIndex: false,
  //       noMore: false,
  //       loading: false,
  //       disabledScroll: true,
  //       formInline: {
  //         couponName: '',
  //       },
  //       userDetail: {},
  //       list: [],
  //       checkedItem: {},
  //       checkedList: [],
  //       queryform: {
  //         current: 1,
  //         size: 10,
  //       },
  //     }
  //   },
  //   methods: {
  //     handleDelete(index) {
  //       this.checkedList.splice(index, 1)
  //     },
  //     checkInput(e, row) {
  //       let num = Number(e.target.value)
  //       console.log(num)
  //       if (isNaN(num) || num <= 0) {
  //         row.count = false
  //         return this.$message.error('非法参数！')
  //       }
  //     },
  //     addItem() {
  //       const index = this.list.findIndex(
  //         (item) => item.id === this.checkedItem.id
  //       )
  //       this.checkedList.push({ ...this.checkedItem, count: false })
  //       this.list.splice(index, 1)
  //     },
  //     async handleSearch() {
  //       this.list = []
  //       this.noMore = false
  //       this.queryform.current = 0
  //       await this.load()
  //     },
  //     handleCouponClick(item, index) {
  //       this.activeIndex = index
  //       this.checkedItem = item
  //     },
  //     async load() {
  //       if (!this.noMore) {
  //         this.loading = true
  //         this.queryform.current++
  //         const {
  //           data: { records },
  //         } = await getCoupon({ ...this.queryform, ...this.formInline })
  //         records.length < 10 && (this.noMore = true)
  //         this.list = this.list.concat(records)
  //         this.loading = false
  //       }
  //     },
  //     async show(data) {
  //       console.log('show')
  //       this.disabledScroll = false
  //       console.log(this.queryform.current)
  //       this.userDetail = data
  //       const {
  //         data: { records },
  //       } = await getCoupon(this.queryform)
  //       this.list = records
  //       this.list.length < 10 && (this.noMore = true)
  //       this.dialogFormVisible = true
  //     },
  //     close() {
  //       this.reset()
  //       this.$emit('fetch')
  //       // console.log(this.queryform.current)
  //       this.dialogFormVisible = false
  //     },
  //     handleConfirm() {
  //       const { id: userId } = this.userDetail
  //       console.log(userId)
  //       let isError = false
  //       console.log(this.checkedList)
  //       const coupons = this.checkedList.map((item) => {
  //         if (!item.count || !item.id) {
  //           isError = true
  //         }
  //         return {
  //           couponId: item.id,
  //           count: item.count,
  //         }
  //       })
  //       if (isError) {
  //         return this.$message.error('非法参数！请重新配置')
  //       }
  //       addCoupon({
  //         userId,
  //         coupons,
  //       }).then(() => {
  //         this.$message.success('分配优惠券完成！')
  //         this.close()
  //       })
  //     },
  //     reset() {
  //       this.disabledScroll = true
  //       this.queryform.current = 1
  //       console.log('reset')
  //       console.log(this.queryform.current)
  //       this.queryform = {
  //         current: 1,
  //         size: 10,
  //       }
  //       this.list = []
  //       this.checkedList = []
  //       this.checkedItem = {}
  //       this.noMore = false
  //       this.formInline.couponName = ''
  //     },
  //   },
  // }
</script>

<style scoped>
  .el-alert {
    padding: 0 !important;
  }
  .active {
    background-color: rgb(245, 245, 245);
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .close-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    color: #fff;
    cursor: pointer;
    background: #bbb;
    border-radius: 50%;
  }
  .close-badge:hover {
    background-color: red;
  }
</style>
