<template>
  <el-dialog :before-close="handleClose" title="新增就医记录" :visible.sync="dialogVisible" width="50%">
    <span>
      <el-form ref="ruleForm" label-width="100px" :model="ruleForm" :rules="rules">
        <div class="demo-ruleForms">
          <el-form-item label="就医单位" prop="title">
            <el-input v-model="ruleForm.title" placeholder="请输入 就医单位" />
          </el-form-item>
          <el-form-item label="就诊科室" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入 就诊科室" />
          </el-form-item>
          <el-form-item label="就医类型" prop="fitType">
            <el-select v-model="ruleForm.fitType" placeholder="请选择 就医类型">
              <el-option v-for="(ym, index) in checknum" :key="index" :label="ym.labelName" :value="ym.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="就医卡号" prop="fitNum">
            <el-input v-model="ruleForm.fitNum" placeholder="请输入 就医卡号" />
          </el-form-item>
          <el-form-item label="就医时间" prop="phyTime">
            <el-date-picker v-model="ruleForm.phyTime" align="center" placeholder="选择就医时间" type="date" value-format="yyyy-MM-dd" />
          </el-form-item>
          <el-form-item label="出院时间" prop="leaveTime">
            <el-date-picker v-model="ruleForm.leaveTime" align="center" placeholder="选择出院时间" type="date" value-format="yyyy-MM-dd" />
          </el-form-item>
          <el-form-item label="主管医生" prop="medicName">
            <el-input v-model="ruleForm.medicName" placeholder="请输入 主管医生" />
          </el-form-item>
          <el-form-item label="当前状态" prop="fitEnable">
            <el-select v-model="ruleForm.fitEnable" placeholder="请选择 当前状态">
              <el-option v-for="(ym, index) in labType" :key="index" :label="ym.labelName" :value="ym.id" />
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="主诉" prop="chiefTitle">
          <el-input v-model="ruleForm.chiefTitle" placeholder="请输入 主诉" />
        </el-form-item>
        <el-form-item label="检查" prop="mark">
          <el-input v-model="ruleForm.mark" placeholder="请输入 检查" />
        </el-form-item>
        <el-form-item label="诊断" prop="diagnosis">
          <el-input v-model="ruleForm.diagnosis" placeholder="请输入 诊断" />
        </el-form-item>
        <el-form-item label="治疗方案" prop="precept">
          <el-input v-model="ruleForm.precept" placeholder="请输入 治疗方案" />
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userFitnessInfoadd } from '@/api/mall/users/usermore'
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        title: [{ required: true, message: '请输入 就医单位', trigger: 'blur' }],
        name: [{ required: true, message: '请输入 就诊科室', trigger: 'blur' }],
        fitNum: [{ required: true, message: '请输入 就医卡号', trigger: 'blur' }],
        phyTime: [{ type: 'string', required: true, message: '请选择 就医时间', trigger: 'change' }],
        leaveTime: [{ type: 'string', required: true, message: '请选择 出院时间', trigger: 'change' }],
        fitType: [{ required: true, message: '请选择 就医类型', trigger: 'change' }],
        medicName: [{ required: true, message: '请输入 主管医生', trigger: 'blur' }],
        fitEnable: [{ required: true, message: '请选择 当前状态', trigger: 'change' }],
        chiefTitle: [{ required: true, message: '请输入 主诉', trigger: 'blur' }],
        mark: [{ required: true, message: '请输入 检查', trigger: 'blur' }],
        diagnosis: [{ required: true, message: '请输入 诊断', trigger: 'blur' }],
        precept: [{ required: true, message: '请输入 治疗方案', trigger: 'blur' }],
      },
      dialogVisible: false,
      uphide: false,
      sub: 0,
      checknum: [
        {
          id: 1,
          labelName: '门诊',
        },
        {
          id: 2,
          labelName: '住院',
        },
      ],
      labType: [
        {
          id: 1,
          labelName: '已康复',
        },
        {
          id: 2,
          labelName: '治疗中',
        },
      ],
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dialogVisible = newValue
        this.uphide = newValue
        this.$forceUpdate()
      }
    },
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          userFitnessInfoadd({ userId: this.value.userId, fitStatus: 7, ...this.ruleForm }).then((res) => {
            if (res.status == 200) {
              this.ruleForm = {
                fitUrl: [],
              }
              this.$emit('addshows', false)
            }
          })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.$emit('addshows', false)
      this.$refs.ruleForm.resetFields()
    },
    check(e) {
      if (e.url) {
        this.ruleForm.fitUrl.forEach((item, index) => {
          if (item == e.url) {
            this.ruleForm.fitUrl.splice(index, 1)
          }
        })
      } else {
        this.ruleForm.fitUrl.push(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-ruleForms {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }
  .el-select,
  .el-date-editor,
  .input__inner {
    width: 100%;
  }
}
</style>