<template>
  <div class="container">
    <div class="ym_swiper">
      <div v-for="(ym,index) in 10" :key="index" :class="cur==index?'active item':'item'">
        <div class="span">热卖</div>
        <div class="span_txt">热卖推荐</div>
      </div>
    </div>
    <div class="card_multion">
      <div class="card-item">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      cur:0
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  .ym_swiper{
    display: flex;
    height: 59px;
    overflow: hidden;
    .item{
      flex-grow: 1;
      min-width: 80px;
      min-height: 80px;
      margin-right: 12px;
      text-align: center;
      .span{
        font-size: 16px;
      }
      .span_txt{
        width: 100%;
        padding: 2px 5px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 20px;
      }
    }
    .active{
      font-weight: bold;
      .span_txt{
        color: white;
        background: #1780ff;
      }
    }
  }
  .card_multion{
    .card-item{
      width: 100%;
      
    }
  }
}
</style>