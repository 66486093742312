import request from '@/utils/request'
// 省运营中心列表
export function queryOrgPage(data) {
  return request({
    url: '/admin/comSysOrg/queryOrgPage',
    method: 'post',
    data,
  })
}
// 省运营中心（机构树形列表）
export function selectOrgTree() {
  return request({
    url: '/admin/comSysOrg/selectOrgServeTree',
    method: 'post',
  })
}
// 省运营中心（员工）
export function selectOrgUser(data) {
  return request({
    url: '/admin/comSysOrg/selectOrgUser',
    method: 'post',
    data,
  })
}

// 省运营中心（员工）
export function update(data) {
  return request({
    url: '/admin/comBusiSign/setServeUser',
    method: 'post',
    data,
  })
}
// 省运营中心(赠送区域判断哪些区域)
export function checkAreaDistrictOrg(data) {
  return request({
    url: '/admin/comSysOrg/checkAreaDistrictOrg',
    method: 'post',
    data,
  })
}
// 省(区)运营中心(赠送区域保存)
export function addExtraOrg(data) {
  return request({
    url: '/admin/comSysOrg/addExtraOrg',
    method: 'post',
    data,
  })
}
// 机构付款信息详情
export function selectOrgSignList(data) {
  return request({
    url: '/admin/comBusiSign/selectOrgSignList',
    method: 'post',
    data,
  })
}
// 机构付款信息添加提交
export function addOrgSign(data) {
  return request({
    url: '/admin/comBusiSignAmount/add',
    method: 'post',
    data,
  })
}
// 省、区 运营中心_赠送区县_按钮，验证赠送机构数量
export function checkAllCount(data) {
  return request({
    url: '/admin/comSysOrg/checkAllCount',
    method: 'post',
    data,
  })
}

// 省运营中心-数据信息-统计
export function getStoresTongJi(data) {
  return request({
    url: '/shop/stShop/getStoresTongJi',
    method: 'post',
    data,
  })
}
// 省运营中心-数据信息-区代
export function queryOrgDataPage(data) {
  return request({
    url: '/admin/comSysOrg/queryOrgDataPage',
    method: 'post',
    data,
  })
}
// 省运营中心-数据信息-门店
export function queryOrgDataShopPage(data) {
  return request({
    url: '/shop/stShop/queryOrgDataShopPage',
    method: 'post',
    data,
  })
}
// 省运营中心-数据信息-用户
export function queryOrgCustmListForPage(data) {
  return request({
    url: '/shop/stShopCustm/queryOrgCustmListForPage',
    method: 'post',
    data,
  })
}
// 省运营中心-数据信息-推广
export function queryOrgPacksListForPage(data) {
  return request({
    url: '/achievement/cpnGiftPacksComb/queryOrgPacksListForPage',
    method: 'post',
    data,
  })
}
// 省运营中心-数据信息-推广（统计）
export function queryOrgPacksTongJi(data) {
  return request({
    url: '/achievement/cpnGiftPacksComb/queryOrgPacksTongJi',
    method: 'post',
    data,
  })
}
// 省运营中心_数据信息_详情_收益_收益列表
export function queryMerProfitOrgAccountPage(data) {
  return request({
    url: '/merchant/merProfitAmountLog/queryMerProfitOrgAccountPage',
    method: 'post',
    data,
  })
}
// 省运营中心_数据信息_详情_收益_收益统计
export function getMerCapitalEarningVo(data) {
  return request({
    url: '/merchant/merCapitalAccount/getMerCapitalEarningVo',
    method: 'post',
    data,
  })
}
// 省运营中心_数据信息_详情_交易数据（统计）
export function queryOrdOrderCount(data) {
  return request({
    url: '/order/ordShopOrder/queryOrdOrderCount',
    method: 'post',
    data,
  })
}

// 省运营中心_数据信息_详情_交易数据（图表数据）
export function queryOrdOrderShopStatistical(data) {
  return request({
    url: '/order/ordShopOrder/queryOrdOrderShopStatistical',
    method: 'post',
    data,
  })
}
