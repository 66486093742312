<template>
  <div class="comprehensive-table-container">
    <common-form ref="commonForm" v-model="searchForm" :options="searchOptions">
      <template slot="operation">
        <el-button icon="el-icon-search" type="warning">搜索</el-button>
        <el-button icon="el-icon-refresh-left" type="primary">重置</el-button>
      </template>
    </common-form>
    <!-- <div style="display: flex; flex-wrap: wrap; margin-bottom: 15px">
      <el-button type="primary" @click="$router.push({path: '/call/batch',query: { type: 1,},})">批量导入</el-button>
    </div> -->
    <!-- <el-button class="addBtn" type="primary" @click="$router.push({ path: '/call/batch', query: { type: 3 } })">批量导入</el-button> -->
    <div class="ym_tab" style="">
      <el-table border :data="data" max-height="600">
        <el-table-column align="center" label="序号" prop="id" show-overflow-tooltip width="70" />
        <el-table-column align="center" label="日期" prop="createDate" width="150" />
        <el-table-column align="center" label="姓名" prop="userName" show-overflow-tooltip />
        <el-table-column align="center" label="电话1" prop="phone" width="150">
          <template #default="scope">
            <div class="solt" style="display: flex; align-items: center">
              <div class="phone_icon" style="width: 20px; height: 20px; border-radius: 100%; background-color: #ecf4fc">
                <i class="el-icon-phone" style="color: #1890ff"></i>
              </div>
              {{ scope.row.phone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="电话2" prop="phonev" width="150">
          <template #default="scope">
            <div class="solt" style="display: flex; align-items: center">
              <div class="phone_icon" style="width: 20px; height: 20px; border-radius: 100%; background-color: #ecf4fc">
                <i class="el-icon-phone" style="color: #1890ff"></i>
              </div>
              {{ scope.row.phonev }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="渠道" prop="channel" show-overflow-tooltip />
        <el-table-column align="center" label="地址" prop="addressDetail" show-overflow-tooltip />
        <el-table-column align="center" label="备注1" prop="remark" show-overflow-tooltip />
        <el-table-column align="center" label="备注2" prop="remarkContent" show-overflow-tooltip />
        <el-table-column align="center" label="销售状态" prop="" show-overflow-tooltip />
        <el-table-column align="center" label="客情状态" prop="" show-overflow-tooltip />
        <el-table-column align="center" label="拨打状态" prop="" show-overflow-tooltip />
        <el-table-column align="center" fixed="right" label="操作" width="260">
          <template #default="{ row }">
            <!-- <el-button size="default" type="text" @click="show(row)">
              查看
            </el-button> -->
            <el-button size="default" type="text" @click="$router.push({ path: '/call/editTab', query: { date: row } })">编辑</el-button>
            <el-button size="default" type="text" @click="history(row)">历史</el-button>
            <el-button size="default" type="text" @click="edit(row)">注册</el-button>
            <el-button size="default" type="text" @click="appoint(row)">移交</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <table-edit ref="edit" v-model="form" :config="config" :options="options" :rules="rules" @fileStatus="fileupload" @submit="submit" />
    <el-pagination background :layout="pageLayout" :total="total" @current-change="currentChange" @size-change="sizeChange" />
    <handover :hanuser="handeuserinfo" :showtab="appointDialog" @closover="colsove" />
    <histroy :showhislist="historylist" :showhist="historyshow" @closehis="colsehistory" />
  </div>
</template>

<script>
  import { queryPage } from '@/api/mall/health/call/myClient'
  import { options, rules, config, searchOptions } from '@/const/call/myClient'
  import { deepClone } from '@/utils/util'
  import { mapGetters } from 'vuex'
  import handover from './components/handover.vue'
  import histroy from './components/history.vue'
  export default {
    components: {
      handover,
      histroy,
    },
    data() {
      return {
        searchForm: { current: 1, size: 10 },
        total: 0,
        data: [],
        options,
        searchOptions,
        historylist: '',
        historyshow: false,
        rules,
        config,
        form: {},
        handeuserinfo: null,
        appointDialog: false,
      }
    },
    computed: {
      ...mapGetters({
        userId: 'user/userId',
        type: 'user/type',
      }),
    },
    created() {
      this.list()
    },
    methods: {
      history(row) {
        this.historyshow = true
        this.historylist = row
      },
      colsehistory() {
        this.historyshow = false
      },
      colsove() {
        this.appointDialog = false
      },
      appoint(row) {
        this.handeuserinfo = row
        if (this.handeuserinfo != null) {
          this.appointDialog = true
        } else {
          this.$message.error('请选择移交用户')
        }
      },
      list() {
        queryPage({
          ...options,
          ...this.searchForm,
          type: this.type,
          sysId: this.userId,
        }).then((res) => {
          const {
            data: { records, size },
          } = res
          console.log(records)
          this.data = records
          this.total = size
          console.log(res)
        })
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      fileupload(msg, result) {
        console.log('成功回调')
        console.log(msg, result)
      },
      submit() {
        // console.log('表单提交', e)
        console.log('表单数据user', this.form)
      },
      show(row) {
        this.form = deepClone(row)
        // this.$refs['edit'].setfile([{ url: row.avatar }])
        this.$nextTick(() => {
          this.$refs['edit'].showEdit('show')
        })
      },
      edit(row) {
        this.form = deepClone(row)
        // this.$refs['edit'].setfile([{ url: row.imgUrl }])
        // this.$nextTick(() => {
        //   this.$refs['edit'].showEdit('edit')
        // })
        this.$router.push({ path: '/call/editTab', query: { date: this.form } })
      },
      currentChange(e) {
        this.searchForm.current = e
        this.list()
      },
      sizeChange(e) {
        this.searchForm.current = 1
        this.searchForm.size = e
        this.list()
      },
    },
  }
</script>

<style></style>
