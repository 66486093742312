import request from '@/utils/request'
// 员工绩效-员工列表
export function queryPage(data) {
  return request({
    url: `/achievement/tx/queryPage`,
    method: 'post',
    data,
  })
}
// 员工绩效-指定员工类型
export function changeUserIsFull(data) {
  return request({
    url: `/achievement/tx/changeUserIsFull`,
    method: 'post',
    data,
  })
}
// 员工绩效-查看-礼包组合
export function queryList(data) {
  return request({
    url: `/achievement/cpnGiftPacksComb/queryList`,
    method: 'post',
    data,
  })
}
