import request from '@/utils/request'

export function getGoodsComments(data) {
  return request({
    url: `/goods/proGoodsEvaLabRel/page`,
    method: 'post',
    data,
  })
}

export function updateGoodsComments(data) {
  return request({
    url: `/goods/proGoodsEvaLabRel/update`,
    method: 'post',
    data,
  })
}
