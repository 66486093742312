<template>
  <div class="comprehensive-table-container">
    <div class="m-b">
      <el-tag
        v-for="tag in dynamicTags"
        :key="tag"
        closable
        :disable-transitions="false"
        size="medium"
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        v-if="inputVisible"
        ref="saveTagInput"
        v-model="inputValue"
        class="input-new-tag"
        size="mini"
        @blur="handleInputConfirm"
        @keyup.enter.native="handleInputConfirm"
      />
      <el-button v-else class="m-l" size="mini" @click="showInput">
        + New Tag
      </el-button>
    </div>
    <el-table :data="[]">
      <template v-for="items in header">
        <el-table-column
          :key="items.prop"
          :label="items.label"
          :prop="items.prop"
        />
      </template>
    </el-table>
  </div>
</template>

<script>
  export default {
    name: 'MultipleSpec',
    data() {
      return {
        header: [
          { label: '图片', prop: 'img' },
          { label: 'sku编码', prop: 'skucode' },
          { label: '销售价(元)', prop: 'skucode' },
          { label: '市场价(元)', prop: 'skucode' },
          { label: '成本价(元)', prop: 'skucode' },
          { label: '库存', prop: 'skucode' },
          { label: '重量(kg)', prop: 'skucode' },
          { label: '体积(m³)', prop: 'skucode' },
          { label: '一级返佣', prop: 'skucode' },
          { label: '二级返佣', prop: 'skucode' },
          // { label: '是否启用', prop: 'skucode' },
        ],
        data: [{ img: '图片' }],
        dynamicTags: ['标签一', '标签二', '标签三'],
        inputVisible: false,
        inputValue: '',
      }
    },
    methods: {
      handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
      },

      showInput() {
        this.inputVisible = true
        this.$nextTick((_) => {
          this.$refs.saveTagInput.$refs.input.focus()
          console.log(_)
        })
      },

      handleInputConfirm() {
        let inputValue = this.inputValue
        if (inputValue) {
          this.dynamicTags.push(inputValue)
        }
        this.inputVisible = false
        this.inputValue = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
