import request from '@/utils/request'
// 收银机管理列表
export function queryPage(data) {
  return request({
    url: '/shop/stShopCashRegister/queryPage',
    method: 'post',
    data,
  })
}
// 收银机关联门店
export function edit(data) {
  return request({
    url: '/shop/stShopCashRegister/edit',
    method: 'post',
    data,
  })
}
