var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("vab-icon", { attrs: { icon: "road-map-line" } }),
              _vm._v(" 区域订单 "),
              _c(
                "el-tag",
                { staticClass: "card-header-tag", attrs: { type: "warning" } },
                [_vm._v(" 区域订单分析 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("vab-chart", {
        staticStyle: { height: "283px" },
        attrs: {
          "init-options": _vm.initOptions,
          option: _vm.option,
          theme: "vab-echarts-theme",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }