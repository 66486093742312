const options = [
  { label: '昵称', span: 12, prop: 'q1' },
  { label: '账号', span: 12, prop: 'q2' },
  { label: '密码', span: 12, prop: 'q3' },
  {
    label: '店铺',
    span: 12,
    prop: 'q4',
    type: 'select',
    key: 'name',
    value: 'id',
    url: '/mall/shopinfo/list',
    optionData: [],
  },
  { label: '手机号', span: 12, prop: 'q5' },
  { label: '邮箱', span: 12, prop: 'q6' },
  { label: '头像', span: 24, prop: 'q7', type: 'upload' },
]

const rules = {
  a: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}

const searchOptions = [
  {
    label: '店铺',
    type: 'select',
    prop: 'isopen',
    span: 4,
    labelWidth: '3em',
    key: 'name',
    value: 'id',
    url: '/mall/shopinfo/list',
    optionData: [],
  },
  {
    label: '账号',
    prop: 'isopen33',
    span: 4,
    labelWidth: '3em',
  },
  { type: 'operation', span: 4 },
]
const config = {}
export { options, rules, config, searchOptions }
