import { render, staticRenderFns } from "./WxEditor.vue?vue&type=template&id=86d2ef60"
import script from "./WxEditor.vue?vue&type=script&lang=js"
export * from "./WxEditor.vue?vue&type=script&lang=js"
import style0 from "./WxEditor.vue?vue&type=style&index=0&id=86d2ef60&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\健康约-后管-前端\\health-front-backend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('86d2ef60')) {
      api.createRecord('86d2ef60', component.options)
    } else {
      api.reload('86d2ef60', component.options)
    }
    module.hot.accept("./WxEditor.vue?vue&type=template&id=86d2ef60", function () {
      api.rerender('86d2ef60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/editor/WxEditor.vue"
export default component.exports