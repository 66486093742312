import request from '@/utils/request'
// 机构推荐签约列表
export function getList(data) {
  return request({
    url: '/admin/comBusiOrgApply/querySigningTeacherOrgApplyPage',
    method: 'post',
    data,
  })
}
// 机构推荐签约列表（统计数据）
export function getCount(data) {
  return request({
    url: '/admin/comBusiOrgApply/ComBusiSingTeacherTjOrgApplyCount',
    method: 'post',
    data,
  })
}

// 机构推荐签约列表（查看-推荐信息）
export function getDetailRecommend(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectSigningTeacherOrgApplyDetailInfo',
    method: 'post',
    data,
  })
}
// 机构推荐签约列表（查看-签约信息）
export function getDetailSign(data) {
  return request({
    url: '/org/comBusiSignLecturerTalk/queryTalkSucessPage',
    method: 'post',
    data,
  })
}
// 机构推荐签约列表（查看-洽谈信息）
export function getDetailTalk(data) {
  return request({
    url: '/org/comBusiSignLecturerTalk/queryDiscussPage',
    method: 'post',
    data,
  })
}
// 机构推荐签约列表（查看-无效信息）
export function getDetailInvalid(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectSigningTeacherOrgApplyInvalidInfo',
    method: 'post',
    data,
  })
}
// 机构推荐签约列表（设置无效信息）
export function setInvalid(data) {
  return request({
    url: '/admin/comBusiOrgApply/updateSettingOrgApply',
    method: 'post',
    data,
  })
}
// 机构推荐签约列表洽谈中（失败记录）
export function queryTalFailureLogPage(data) {
  return request({
    url: '/org/comBusiSignLecturerTalkLog/queryTalFailureLogPage',
    method: 'post',
    data,
  })
}
// 机构推荐签约列表已敲定（确认签约）
export function editSingSucess(data) {
  return request({
    url: '/org/comBusiSignLecturerTalkLog/editSingSucess',
    method: 'post',
    data,
  })
}
