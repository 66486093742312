import request from '@/utils/request'
// 门店列表
export function queryOrgShopPage(data) {
  return request({
    url: '/admin/comSysOrg/queryOrgShopPage',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_顾客列表
export function queryPage(data) {
  return request({
    url: '/shop/stShopCustm/queryPage',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_门店评分
export function queryShopEval(data) {
  return request({
    url: '/shop/stShopEval/queryShopEval',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_顾客数量
export function queryCustmCount(data) {
  return request({
    url: '/shop/stShopCustm/queryCustmCount',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_顾客详情
export function queryCustmDetail(data) {
  return request({
    url: '/shop/stShopCustm/queryCustmDetail',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_顾客订单
export function queryCustmOrder(data) {
  return request({
    url: '/shop/stShopCustm/queryCustmOrder',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_进货（统计）
export function getOrderShopReplenByOrgCode(data) {
  return request({
    url: '/user/merOrgPurchaseRecord/getOrderShopReplenByOrgCode',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_进货（额度记录表）
export function stockQueryPage(data) {
  return request({
    url: '/user/merPrepareBalanceLog/queryPage',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_进货（进货订单列表）
export function queryShopPage(data) {
  return request({
    url: '/order/ordShopOrder/queryOrgShopPage',
    method: 'post',
    data,
  })
}
// 门店中心_数据信息_详情_顾客_顾客积分记录
export function queryPageShop(data) {
  return request({
    url: '/shop/stShopCustmIntegralLog/queryPage',
    method: 'post',
    data,
  })
}
// 门店列表-修改（基础信息）
export function editOrg(data) {
  return request({
    url: '/admin/comSysOrg/editOrg',
    method: 'post',
    data,
  })
}
// 门店列表-修改（修改读取数据）
export function selectOrgTypeDetails(data) {
  return request({
    url: '/admin/comSysOrg/selectOrgTypeDetails',
    method: 'post',
    data,
  })
}
