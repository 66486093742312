import request from '@/utils/request'

export function queryPage(query) {
  return request({
    url: '/mall/articlecategory/page',
    method: 'get',
    params: query,
  })
}

export function addObj(obj) {
  return request({
    url: '/mall/articlecategory',
    method: 'post',
    data: obj,
  })
}

export function getObj(id) {
  return request({
    url: '/mall/articlecategory/' + id,
    method: 'get',
  })
}

export function delObj(id) {
  return request({
    url: '/mall/articlecategory/' + id,
    method: 'delete',
  })
}

export function putObj(obj) {
  return request({
    url: '/mall/articlecategory',
    method: 'put',
    data: obj,
  })
}

// // 栏目列表
// export function queryPage(data) {
//   return request({
//     url: `/bbs/bbsArticleColumn/queryPage`,
//     method: 'post',
//     data,
//   })
// }
// 栏目编辑
export function editColumn(data) {
  return request({
    url: `/bbs/bbsArticleColumn/edit`,
    method: 'post',
    data,
  })
}
// 栏目添加
export function addColumn(data) {
  return request({
    url: `/bbs/bbsArticleColumn/add`,
    method: 'post',
    data,
  })
}
// 栏目删除
export function deleteColumn(data) {
  return request({
    url: `/bbs/bbsArticleColumn/delete`,
    method: 'post',
    data,
  })
}
// 栏目状态
export function disableColumn(data) {
  return request({
    url: `/bbs/bbsArticleColumn/disable`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function queryListForList(data) {
  return request({
    url: `/bbs/bbsArticleType/queryListForList`,
    method: 'post',
    data,
  })
}
// 配置类型（所有文章类型）
export function addColumnType(data) {
  return request({
    url: `/user/bbsArticleColumnType/add`,
    method: 'post',
    data,
  })
}
