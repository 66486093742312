export function stringToBin(str) {
  var result = ''
  console.log(str)
  for (var i = 0; i < str.length; i++) {
    var charCode = str.charCodeAt(i).toString(2)
    result += new Array(9 - charCode.length).join('0') + charCode
  }
  return result
}

export function BinToStr(Bin) {
  var result = ''
  for (var i = 0; i < Bin.length; i += 8) {
    result += String.fromCharCode(parseInt(Bin.substr(i, 8), 2))
  }
  return result
}
export function base64Encode(str) {
  if (!str) return ''
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1)
      }
    )
  )
}
export function base64Decode(str) {
  if (!str) return ''
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}
