const options = [
  { label: '头像', span: 24, prop: 'headimgUrl', type: 'upload' },
  { label: '昵称', span: 12, prop: 'nickName' },
  { label: '手机号', span: 12, prop: 'phone' },
  { label: '城市', span: 12, prop: 'city' },
]

const rules = {
  a: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}

const searchOptions = [
  {
    label: '手机号码：',
    prop: 'phone',
    span: 5,
    // labelWidth: '3em',
  },
  { type: 'operation', span: 6 },
]
const config = {}
export { options, rules, config, searchOptions }
