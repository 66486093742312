<template>
  <el-card shadow="hover">
    <template #header>
      <vab-icon icon="send-plane-2-line" />
      商品销量排行
      <el-tag class="card-header-tag" type="success">商品销量分析</el-tag>
    </template>
    <el-table :data="tableData" height="283px" row-key="title">
      <el-table-column align="center" label="拖拽" width="50px">
        <template #default="{}">
          <vab-icon icon="drag-move-line" style="cursor: pointer" />
        </template>
      </el-table-column>
      <el-table-column width="20px" />
      <el-table-column label="商品名称" prop="" width="230px">
        <template #default="scope">
          <div class="tkx-tt">{{ scope.row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="库存占比" width="220px">
        <template #default="{ row }">
          <el-progress color="#1890FF" :percentage="row.stock" />
        </template>
      </el-table-column>
      <el-table-column width="50px" />
      <el-table-column label="订单数量" prop="ct" />
    </el-table>
  </el-card>
</template>

<script>
import Sortable from 'sortablejs'
import { spusale } from '@/api/mall/order/largescreen'
export default {
  data() {
    return {
      tableData: [],
    }
  },
  created() {
    this.spusale()
  },
  mounted() {
    const tbody = document.querySelector('.el-table__body-wrapper tbody')
    const _this = this
    Sortable.create(tbody, {
      onEnd({ newIndex, oldIndex }) {
        const currRow = _this.tableData.splice(oldIndex, 1)[0]
        _this.tableData.splice(newIndex, 0, currRow)
      },
    })
  },
  methods: {
    spusale() {
      spusale({}).then((res) => {
        this.tableData = res.data.spu
      })
    },
  },
}
</script>
<style scoped lang="scss">
.tkx-tt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
