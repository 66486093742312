import request from '@/utils/request'
export function statistics(query) {
  return request({
    url: '/mall/largescreen/xinStatistics',
    method: 'get',
    params: query,
  })
}
// export function spusale(query) {
//   return request({
//     url: '/mall/largescreen/statustics/spusale',
//     method: 'get',
//     params: query,
//   })
// }

export function spusale(query) {
  return request({
    url: '/mall/largescreen/statistics/spusale',
    method: 'get',
    params: query,
  })
}
