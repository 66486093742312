const searchOptions = [
  {
    label: '店铺',
    type: 'select',
    prop: 'isopen2',
    span: 4,
    labelWidth: '5em',
  },
  {
    label: '商品名称',
    prop: 'isopen3',
    span: 4,
    labelWidth: '5em',
  },
  {
    label: '商城类目',
    prop: 'isopen4',
    type: 'select',
    span: 4,
    labelWidth: '5em',
  },
  {
    label: '商品编码',
    prop: 'isopen5',
    span: 4,
    labelWidth: '5em',
  },
  {
    label: '规格类型',
    prop: 'isopen6',
    type: 'select',
    span: 4,
    labelWidth: '5em',
  },
  {
    label: '是否上架',
    prop: 'isopen7',
    type: 'select',
    span: 4,
    labelWidth: '5em',
  },
  {
    label: '积分抵扣',
    prop: 'isopen8',
    type: 'select',
    span: 4,
    labelWidth: '5em',
  },
  { type: 'operation', span: 4 },
]

export { searchOptions }
