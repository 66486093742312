import request from '@/utils/request'

export function getBrandList(data) {
  return request({
    url: `/goods/proGoodsBrand/page`,
    method: 'post',
    data,
  })
}

export function disableBrand(data) {
  return request({
    url: `/goods/proGoodsBrand/disable`,
    method: 'post',
    data,
  })
}

export function recoveryBrand(data) {
  return request({
    url: `/goods/proGoodsBrand/recovery`,
    method: 'post',
    data,
  })
}

export function deleteBrand(data) {
  return request({
    url: `/goods/proGoodsBrand/delete`,
    method: 'post',
    data,
  })
}

export function addBrand(data) {
  return request({
    url: `/goods/proGoodsBrand/add`,
    method: 'post',
    data,
  })
}

export function updateBrand(data) {
  return request({
    url: `/goods/proGoodsBrand/update`,
    method: 'post',
    data,
  })
}
// 商品分类
export function levelList() {
  return request({
    url: `/goods/proGoodsCategory/levelList`,
    method: 'post',
  })
}
