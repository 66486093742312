var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("vab-icon", { attrs: { icon: "send-plane-2-line" } }),
              _vm._v(" 商品销量排行 "),
              _c(
                "el-tag",
                { staticClass: "card-header-tag", attrs: { type: "success" } },
                [_vm._v("商品销量分析")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, height: "283px", "row-key": "title" } },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "拖拽", width: "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({}) {
                  return [
                    _c("vab-icon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { icon: "drag-move-line" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { width: "20px" } }),
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "", width: "230px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "tkx-tt" }, [
                      _vm._v(_vm._s(scope.row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "库存占比", width: "220px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-progress", {
                      attrs: { color: "#1890FF", percentage: row.stock },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { width: "50px" } }),
          _c("el-table-column", { attrs: { label: "订单数量", prop: "ct" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }