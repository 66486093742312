const options = [
  {
    label: '积分赠送',
    span: 24,
    prop: 'jfzs',
    type: 'radio',
    optionData: [
      { label: '是', value: 1 },
      { label: '否', value: 2 },
    ],
  },
  {
    label: '',
    span: 24,
    prop: 'giveNumber',
    slot: true,
    labelWidth: '0px',
  },
]

const rules = {
  c: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}
const config = { labelWidth: '8em' }
export { options as giveOptions, rules, config }
