import request from '@/utils/request'

export function getUserBlackList(data) {
  return request({
    url: `/user/uUserDis/queryPage`,
    method: 'post',
    data,
  })
}

export function recoveryBlackList(data) {
  return request({
    url: `/user/uUserLogin/recovery`,
    method: 'post',
    data,
  })
}
