var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c("el-container", { staticStyle: { width: "100%" } }, [
        _c(
          "div",
          { ref: "contantRef", staticClass: "contantAuthorizeUrl" },
          [
            _c(
              "div",
              { staticClass: "mb10" },
              [
                _c(
                  "el-form",
                  {
                    ref: "refGeneral",
                    attrs: { inline: true, model: _vm.listQuery },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c("el-input", {
                          staticClass: "filter-input",
                          attrs: {
                            clearable: "",
                            placeholder: "岗位编码",
                            type: "text",
                          },
                          model: {
                            value: _vm.listQuery.postCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "postCode", $$v)
                            },
                            expression: "listQuery.postCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-input",
                            attrs: { clearable: "", placeholder: "岗位类型" },
                            model: {
                              value: _vm.listQuery.postType,
                              callback: function ($$v) {
                                _vm.$set(_vm.listQuery, "postType", $$v)
                              },
                              expression: "listQuery.postType",
                            },
                          },
                          _vm._l(_vm.postTypeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-search", type: "primary" },
                            on: { click: _vm.handleQuery },
                          },
                          [_vm._v(" 查询 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-refresh-right",
                              type: "primary",
                            },
                            on: { click: _vm.handleReset },
                          },
                          [_vm._v(" 重置 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticStyle: { float: "right" } },
                      _vm._l(_vm.searchButton, function (item) {
                        return _c(
                          "el-button",
                          {
                            key: item.id,
                            attrs: {
                              plain:
                                item.cssType && item.cssType == "plain"
                                  ? true
                                  : false,
                              type: item.buttonCss,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.handleClickBtn(item)
                              },
                            },
                          },
                          [
                            !item.buttonIcon
                              ? _c("span")
                              : _c("vab-icon", {
                                  attrs: { icon: item.buttonIcon },
                                }),
                            _vm._v(" " + _vm._s(item.buttonName) + " "),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "filter-btn" }),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "orgTableDataList",
                staticStyle: { width: "100%" },
                attrs: { border: "", data: _vm.list },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    "show-overflow-tooltip": "",
                    type: "selection",
                    width: "55",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "岗位上下级名称",
                    prop: "postName",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "岗位编码",
                    prop: "postCode",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "岗位类型",
                    prop: "postType",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.postType == 1 ? "主岗位 " : "副岗位"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "状态",
                    prop: "status",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: row.status == 0 ? "danger" : "success",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(row.status == 0 ? "隐藏 " : "显示") +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "岗位描述",
                    prop: "postDescription",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "创建时间",
                    prop: "createTime",
                    "show-overflow-tooltip": "",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("formatDate")(row.createTime)) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.operationShow
                  ? _c("el-table-column", {
                      attrs: { align: "center", label: "操作" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _vm._l(_vm.listButton, function (item) {
                                return _c(
                                  "el-button",
                                  {
                                    key: item.id,
                                    attrs: {
                                      plain:
                                        item.cssType && item.cssType == "plain"
                                          ? true
                                          : false,
                                      type: item.buttonCss,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleClickBtn(item, row)
                                      },
                                    },
                                  },
                                  [
                                    !item.buttonIcon
                                      ? _c("span")
                                      : _c("vab-icon", {
                                          attrs: { icon: item.buttonIcon },
                                        }),
                                    _vm._v(" " + _vm._s(item.buttonName) + " "),
                                  ],
                                  1
                                )
                              })
                            },
                          },
                        ],
                        null,
                        false,
                        1943753687
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.addVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "addDialog",
              attrs: {
                "close-on-click-modal": false,
                title: _vm.dialogTitle,
                visible: _vm.addVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.addVisible = $event
                },
                close: _vm.close,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.addForm,
                    rules: _vm.addFormRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "postPare",
                      attrs: { label: "所属机构", prop: "orgId" },
                    },
                    [
                      _c("el-cascader", {
                        ref: "cascaderMallCatergory",
                        staticStyle: { width: "90%" },
                        attrs: {
                          clearable: "",
                          disabled: _vm.dialogTitle === "编辑",
                          options: _vm.dataSourceLists,
                          props: _vm.props,
                        },
                        on: { change: _vm.handelCascader },
                        model: {
                          value: _vm.addForm.orgId,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "orgId", $$v)
                          },
                          expression: "addForm.orgId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "岗位名称", prop: "postCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "", placeholder: "请选择职务" },
                          model: {
                            value: _vm.addForm.postCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "postCode", $$v)
                            },
                            expression: "addForm.postCode",
                          },
                        },
                        _vm._l(_vm.postList, function (item) {
                          return _c("el-option", {
                            key: item.postCode,
                            attrs: {
                              label: item.postName,
                              value: item.postCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "岗位类型",
                        prop: "postType",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addForm.postType,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "postType", $$v)
                            },
                            expression: "addForm.postType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("主岗位"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("副岗位"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status", required: "" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "status", $$v)
                            },
                            expression: "addForm.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("显示"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("隐藏"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "岗位描述", prop: "postDescription" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          clearable: "",
                          placeholder: "请输入",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.addForm.postDescription,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addForm,
                              "postDescription",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addForm.postDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogTitle === "新增"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v(" 确 定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }