import request from '@/utils/request'

export function getGoodsBrand(data) {
  return request({
    url: `/goods/proGoodsBrand/list`,
    method: 'post',
    data,
  })
}
export function getGoodsTemplate(data) {
  return request({
    url: `/goods/proGoodsSpecsTemplate/paramList`,
    method: 'post',
    data,
  })
}

export function getArea(data) {
  return request({
    url: `/sys/sysArea/list`,
    method: 'post',
    data,
  })
}

export function getAttrList(data) {
  return request({
    url: `/goods/proGoodsSpecsTemplate/attrList`,
    method: 'post',
    data,
  })
}

export function getDetail(data) {
  return request({
    url: `/goods/proGoodsBasics/selectGoodsBase`,
    method: 'post',
    data,
  })
}

export function getDetailImg(data) {
  return request({
    url: `/goods/proGoodsImage/selectGoodsImg`,
    method: 'post',
    data,
  })
}

export function getGoodsSpecs(data) {
  return request({
    url: `/goods/proGoodsBasicsSpecs/goodsSpecs`,
    method: 'post',
    data,
  })
}

export function getGoodsSettings(data) {
  return request({
    url: `/goods/proGoodsBasicsSetting/details`,
    method: 'post',
    data,
  })
}

export function getGoodsAreas(data) {
  return request({
    url: `/sys/sysArea/selectAreaTree`,
    method: 'post',
    data,
  })
}

export function addGoods(data) {
  return request({
    url: `/goods/proGoodsBasics/add`,
    method: 'post',
    data,
  })
}

export function updateGoods(data) {
  return request({
    url: `/goods/proGoodsBasics/update`,
    method: 'post',
    data,
  })
}

export function getGoodsCateGory(data) {
  return request({
    url: `/goods/proGoodsCategory/tree`,
    method: 'post',
    data,
  })
}
