var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "调整用户收益", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _c("span", [_vm._v("当前收益（可提现）")]),
            _c(
              "span",
              { staticStyle: { "font-size": "20px", "font-weight": "700" } },
              [_vm._v(" ￥" + _vm._s(_vm.userDetail.profitAmount) + " ")]
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "调整类型：", prop: "amount" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              _vm.form.amount = 0
                            },
                          },
                          model: {
                            value: _vm.form.optType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "optType", $$v)
                            },
                            expression: "form.optType",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v("冻结"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 2 } }, [
                            _vm._v("解冻"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { style: _vm.form.optType === 2 ? "" : "margin-top: 20px" },
                    [
                      _vm.form.optType === 2
                        ? _c("p", [
                            _vm._v(
                              " 冻结中收益：" +
                                _vm._s(_vm.profitDetail.frozenAmount) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("el-input-number", {
                        attrs: {
                          max: _vm.getMax,
                          min: 0,
                          precision: 2,
                          step: 0.1,
                        },
                        model: {
                          value: _vm.form.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "amount", $$v)
                          },
                          expression: "form.amount",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "remark" } },
                [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注", type: "textarea" },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm()
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }