<!--
  - Copyright (C) 2018-2019
  - All rights reserved, Designed By
  - 注意：
  - 
  - 购买后可获得全部源代码（禁止转卖、分享、上传到码云、github等开源平台）
  - 一经发现盗用、分享等行为，将追究法律责任，后果自负
-->
<template>
  <div id="wxEditor">
    <froala
      v-if="editorType == '2'"
      v-model="content"
      :config="config"
      :tag="'textarea'"
    />
    <div
      v-if="editorType == '1'"
      v-loading="quillUpdateImg"
      element-loading-text="请稍等，图片上传中"
    >
      <!-- 图片上传组件辅助-->
      <el-upload
        :action="serverUrl"
        :before-upload="beforeUpload"
        class="avatar-uploader"
        :data="uploadData"
        :headers="header"
        name="file"
        :on-error="uploadError"
        :on-success="uploadSuccess"
        :show-file-list="false"
      />
      <quillEditor
        ref="myQuillEditor"
        v-model="content"
        class="editor"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @change="onEditorChange($event)"
        @focus="onEditorFocus($event)"
      />
    </div>
  </div>
</template>

<script>
  // import { quillEditor } from "vue-quill-editor"
  import quillEditor from '@/components/quill/quill'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import 'froala-editor/js/plugins.pkgd.min.js'
  import 'froala-editor/js/froala_editor.pkgd.min.js'
  import 'froala-editor/js/plugins/link.min.js'
  import 'froala-editor/js/plugins/image.min.js'
  import 'froala-editor/js/plugins/image_manager.min.js'
  import 'froala-editor/css/plugins/image_manager.min.css'
  import store from '@/store'
  import { getObj2 } from '@/api/editor/configeditor'

  export default {
    name: 'WxEditor',
    components: {
      quillEditor,
    },
    props: {
      uploadData: {
        type: Object,
        default: () => {},
      },
      /*编辑器的内容*/
      value: {
        type: String,
        default: () => {},
      },
      /*图片大小*/
      maxSize: {
        type: Number,
        default: 4000, //kb
      },
    },
    data() {
      return {
        editorType: '',
        config: {
          language: 'zh_cn',
          requestHeaders: {
            Authorization: 'Bearer ' + store.getters.access_token,
          },
          imageUploadURL: '/weixin/wxmaterial/newsImgUpload',
          imageUploadParams: {
            appId: this.uploadData.appId,
          },
          toolbarButtons: {
            moreText: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting',
              ],
            },
            moreParagraph: {
              buttons: [
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote',
              ],
            },
            moreRich: {
              buttons: [
                'insertLink',
                'insertImage',
                'insertVideo',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertHR',
              ],
            },
            moreMisc: {
              buttons: [
                'undo',
                'redo',
                'fullscreen',
                'print',
                'getPDF',
                'spellChecker',
                'selectAll',
                'html',
                'help',
              ],
              align: 'right',
              buttonsVisible: 2,
            },
          },
          fontFamilySelection: true,
          fontSizeSelection: true,
          paragraphFormatSelection: true,
          quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'], //快速插入项
          linkInsertButtons: ['linkBack'],
          imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageManager'],
          imageEditButtons: [
            'imageReplace',
            'imageAlign',
            'imageRemove',
            '|',
            'imageLink',
            'linkOpen',
            'linkEdit',
            'linkRemove',
            '-',
            'imageDisplay',
            'imageStyle',
            'imageAlt',
            'imageSize',
          ],
          imageManagerLoadURL: '/weixin/wxmaterial/page-manager',
          imageManagerLoadParams: {
            appId: this.uploadData.appId,
            type: 'image',
            count: 20,
            offset: 0,
          },
          imageManagerPageSize: 20,
          imageManagerScrollOffset: 1,
          videoInsertButtons: ['videoBack', '|', 'videoByURL'],
          zIndex: 99999,
          events: {
            //https://www.froala.com/wysiwyg-editor/docs/events
            contentChanged: () => {
              this.$emit('input', this.content)
            },
            'imageManager.error': function (error, response) {
              console.log(error, response)
              alert('图片加载出错')
            },
          },
        },

        content: this.value.replace(/data-src/g, 'src'),

        quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
        editorOption: {
          theme: 'snow', // or 'bubble'
          placeholder: '请输入文章内容',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                ['blockquote', 'code-block'], // 引用  代码块
                [{ header: 1 }, { header: 2 }], // 1、2 级标题
                [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                [{ indent: '-1' }, { indent: '+1' }], // 缩进
                // [{'direction': 'rtl'}],                         // 文本方向
                [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                [{ font: [] }], // 字体种类
                [{ align: [] }], // 对齐方式
                ['clean'], // 清除文本格式
                ['link', 'image', 'video'], // 链接、图片、视频
              ],
              // container: "#toolbar",
              handlers: {
                image: function (value) {
                  if (value) {
                    // 触发input框选择图片文件
                    document.querySelector('.avatar-uploader input').click()
                  } else {
                    this.quill.format('image', false)
                  }
                },
                link: function (value) {
                  if (value) {
                    var href = prompt('注意！只支持公众号图文链接')
                    this.quill.format('link', href)
                  } else {
                    this.quill.format('link', false)
                  }
                },
              },
            },
          },
        },
        serverUrl: '/weixin/wxmaterial/newsImgUpload', // 这里写你要上传的图片服务器地址
        header: {
          Authorization: 'Bearer ' + store.getters.access_token,
        }, // 有的图片服务器要求请求头需要有token
      }
    },
    created() {
      this.handleGet()
    },
    methods: {
      handleGet: function () {
        getObj2().then((response) => {
          this.editorType = response.data.data
            ? response.data.data.editorType
            : '1'
        })
      },

      onEditorBlur(editor) {
        //失去焦点事件
        console.log(editor)
      },
      onEditorFocus(editor) {
        console.log(editor)
        //获得焦点事件
      },
      onEditorChange(editor) {
        //内容改变事件
        console.log(editor)
        this.$emit('input', this.content)
      },

      // 富文本图片上传前
      beforeUpload() {
        // 显示loading动画
        this.quillUpdateImg = true
      },

      uploadSuccess(res, file) {
        console.log(file)
        // res为图片服务器返回的数据
        // 获取富文本组件实例
        let quill = this.$refs.myQuillEditor.quill
        // 如果上传成功
        if (res.link) {
          // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片  res.info为服务器返回的图片地址
          quill.insertEmbed(length, 'image', res.link)
          // 调整光标到最后
          quill.setSelection(length + 1)
        } else {
          this.$message.error('图片插入失败')
        }
        // loading动画消失
        this.quillUpdateImg = false
      },
      // 富文本图片上传失败
      uploadError() {
        // loading动画消失
        this.quillUpdateImg = false
        this.$message.error('图片插入失败')
      },
    },
  }
</script>

<style>
  .editor {
    height: 500px;
    line-height: normal !important;
  }
  .ql-snow .ql-tooltip[data-mode='link']::before {
    content: '请输入链接地址:';
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    padding-right: 0px;
    content: '保存';
    border-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode='video']::before {
    content: '请输入视频地址:';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
    content: '10px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
    content: '18px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
    content: '标题1';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
    content: '标题2';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
    content: '标题3';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
    content: '标题4';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
    content: '标题5';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
    content: '标题6';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
    content: '衬线字体';
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
    content: '等宽字体';
  }
</style>
