<template>
  <div v-loading.sync="listLoading" style="padding: 10px">
    <div style="display: flex; align-items: center; justify-content: space-between">
      <div>
        <div style="display: flex">
          <h3 style="margin-right: 10px">当前订单</h3>
          <h3 style="color: rgb(37, 115, 217)">{{ detail.totalOrderNum }}</h3>
        </div>
        <div style="display: flex; margin: 10px 0">
          <div style="margin-right: 20px">
            <span>待付款：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.pendingPayNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>待发货：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.beDeliverNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>待收货：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.beReceivingNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>待评价：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.beEvaluateNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>客情中：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.beAfterSaleNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>已取消：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.cancelledNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>已完成：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.completedNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>待核销：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.beWriteOffNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>待配送：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.beDeliveredNum }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div v-if="btnRolrs[7] && btnRolrs[7]['searchBarButtons'] && btnRolrs[7]['searchBarButtons'].length">
          <div v-if="btnRolrs[7] && btnRolrs[7].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[7].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="display: flex; flex-wrap: wrap; margin-left: -20px">
        <div class="title-item">
          <span>订单编号</span>
          <el-input v-model="formInline.orderCode" class="title-input" placeholder="请输入订单编号" />
        </div>
        <div class="title-item">
          <span>商品名称</span>
          <el-input v-model="formInline.goodsName" class="title-input" placeholder="请输入商品名称" />
        </div>
        <div class="title-item">
          <span>订单类型</span>
          <el-select v-model="formInline.orderType" class="title-input" placeholder="请选择">
            <el-option v-for="(item, index) in orderType" :key="index" :label="item.dictValue" :value="item.dictKey" />
          </el-select>
        </div>
        <div class="title-item">
          <span>订单状态</span>
          <el-select v-model="formInline.orderState" class="title-input" placeholder="请选择">
            <el-option v-for="(item, index) in orderStatus" :key="index" :label="item.dictValue" :value="item.dictKey" />
          </el-select>
        </div>
        <div class="title-item">
          <el-button icon="el-icon-search" type="warning" @click="handleSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-left" type="primary" @click="handleReset">重置</el-button>
        </div>
      </div>
    </div>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="订单编号" prop="orderCode" />
        <el-table-column align="center" label="订单类型" prop="orderType" />
        <el-table-column align="center" label="商品信息" prop="goodsName" />
        <el-table-column align="center" label="订单金额" prop="payableSumAmount" />
        <el-table-column align="center" label="订单状态" prop="orderState">
          <template #default="{ row }">
            <div>{{ row.orderState | initState }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" label="操作">
          <template #default="{ row }">
            <el-button type="primary" @click="openDetail(row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { getOrder, getOrderDetail } from '@/api/mall/distUsers/userDetail'
import { selectSysDict } from '@/api/mall/common'
import { checkButton } from '@/utils/mixins'
export default {
  filters: {
    initState(val) {
      let str = ''
      switch (val) {
        case 10:
          str = '待付款'
          break
        case 11:
          str = '订单取消'
          break
        case 20:
          str = '待发货'
          break
        case 30:
          str = '待收货'
          break
        case 40:
          str = '待评价'
          break
        case 50:
          str = '订单完成'
          break
        case -1:
          str = '全部退货'
          break
      }
      return str
    },
  },
  mixins: [checkButton],
  data() {
    return {
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      userId: 0,
      balanceAmount: 0,
      isSearch: false,
      queryForm: {
        current: 1,
        size: 10,
      },
      total: 0,
      accId: '',
      list: [],
      orderType: [],
      orderStatus: [],
      formInline: {
        orderCode: '',
        goodsName: '',
        orderType: '',
        orderState: '',
      },
      detail: {
        beAfterSaleNum: 0,
        beDeliverNum: 0,
        beDeliveredNum: 0,
        beEvaluateNum: 0,
        beReceivingNum: 0,
        beWriteOffNum: 0,
        cancelledNum: 0,
        completedNum: 0,
        pendingPayNum: 0,
        totalOrderNum: 0,
        userId: null,
      },
    }
  },
  created() {
    const { balanceAmount, accId } = this.$route.query
    this.balanceAmount = balanceAmount
    this.accId = accId
  },
  methods: {
    openDetail(row) {
      const { id } = row
      this.$router.push({
        path: '/orderDetail',
        query: {
          orderId: id,
        },
      })
    },
    handleReset() {
      this.formInline = {
        orderCode: '',
        goodsName: '',
        orderType: '',
        orderState: '',
      }
      this.handleSearch()
    },
    async fetchData(id, options) {
      this.listLoading = true
      this.userId = id
      const {
        data: { records, total },
      } = await getOrder({
        userId: this.userId,
        ...this.queryForm,
        ...options,
      })
      const dicTypeList = await selectSysDict({
        dictTypeList: ['orderType', 'orderStatus'],
      })
      this.orderType = dicTypeList.data[0].dictInfoVoList
      this.orderStatus = dicTypeList.data[1].dictInfoVoList
      const { data: detail } = await getOrderDetail({ userId: this.userId })
      this.detail = detail
      this.total = total
      this.list = records
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.current = 1
      await this.fetchData(this.userId, { ...this.formInline })
      let temp = false
      Object.keys(this.formInline).forEach((key) => {
        this.formInline[key] && (temp = true)
      })
      this.isSearch = temp
    },
    handleSizeChange(val) {
      this.queryForm.size = val
      let data = this.isSearch === true ? this.formInline : {}
      this.fetchData(this.userId, { ...data })
    },
    handleCurrentChange(val) {
      this.queryForm.current = val
      let data = this.isSearch === true ? this.formInline : {}
      this.fetchData(this.userId, { ...data })
    },
    handleExport() {
      // exportIntegral({
      //   accId: this.accId,
      //   userId: this.userId,
      // })
      //   .then((res) => {
      //     const url = window.URL.createObjectURL(new Blob([res]))
      //     const link = document.createElement('a')
      //     link.setAttribute('href', url)
      //     link.setAttribute('download', new Date().getTime() + '.xlsx')
      //     link.click()
      //   })
      //   .catch(() => {
      //     this.$message.error('网络错误，请稍后重试！')
      //   })
    },
  },
}
</script>

<style scoped>
.title-input {
  width: 150px;
  margin-left: 5px;
}
.title-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.title-item span {
  width: 80px;
  text-align: center;
}
</style>
