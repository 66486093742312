import request from '@/utils/request'

export function getGoodsCompare(data) {
  return request({
    url: `/goods/proGoodsBasics/comparePage`,
    method: 'post',
    data,
  })
}

export function updateGoodsCompare(data) {
  return request({
    url: `/goods/proGoodsBasics/updateCompareUrl`,
    method: 'post',
    data,
  })
}
