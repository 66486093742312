import request from '@/utils/request'
// 代销门店列表
export function queryStoresOrgCompanyPage(data) {
  return request({
    url: '/admin/comBusiOrgApply/queryStoresOrgCompanyPage',
    method: 'post',
    data,
  })
}
// 代销门店列表——门店信息详情（门店信息）
export function selectApplyOrgDetails(data) {
  return request({
    url: '/admin/comSysOrg/selectApplyOrgDetails',
    method: 'post',
    data,
  })
}
// 代销门店列表——门店信息详情（企业信息）
export function selectOrgCompDetails(data) {
  return request({
    url: '/admin/comBusiComp/selectOrgCompDetails',
    method: 'post',
    data,
  })
}
// 代销门店列表——门店信息详情（支付信息）
export function selectOrgSignDetails(data) {
  return request({
    url: '/admin/comBusiSign/selectOrgSignDetails',
    method: 'post',
    data,
  })
}
// 代销门店审核列表
export function queryShopCompanyApplyPage(data) {
  return request({
    url: '/admin/comBusiOrgApply/queryShopCompanyApplyPage',
    method: 'post',
    data,
  })
}
// 代销门店审核——添加
export function applyOrgToStore(data) {
  return request({
    url: '/admin/comBusiOrgApply/applyOrgToStore',
    method: 'post',
    data,
  })
}
// 完善信息——判断完善到第几步
export function selectApplyShopOrgStatus(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectApplyShopOrgStatus',
    method: 'post',
    data,
  })
}
// 完善信息 ——详情（第一步）
export function selectOrgApplyDetails(data) {
  return request({
    url: '/admin/comBusiOrgApply/selectOrgApplyDetails',
    method: 'post',
    data,
  })
}
// 完善信息——保存（第一步）
export function addConsSotreOrg(data) {
  return request({
    url: '/admin/comSysOrg/addConsSotreOrg',
    method: 'post',
    data,
  })
}
// 完善信息\修改——详情（第二步）
export function selectComp(data) {
  return request({
    url: '/admin/comBusiComp/selectComp',
    method: 'post',
    data,
  })
}
// 完善信息——保存（第二步）
export function addProxyCompany(data) {
  return request({
    url: '/admin/comBusiComp/addProxyCompany',
    method: 'post',
    data,
  })
}
// 完善信息——详情（第三步）
export function selectSignInfo(data) {
  return request({
    url: '/admin/comBusiSign/selectSignInfo',
    method: 'post',
    data,
  })
}
// 完善信息——保存（第三步）
export function addProxySignFunds(data) {
  return request({
    url: '/admin/comBusiSign/addProxySignFunds',
    method: 'post',
    data,
  })
}
// 修改信息——详情（第一步）
export function selectOrgTypeDetails(data) {
  return request({
    url: '/admin/comSysOrg/selectOrgTypeDetails',
    method: 'post',
    data,
  })
}
// 修改信息——保存（第一步）
export function editProxyShopOrg(data) {
  return request({
    url: '/admin/comSysOrg/editProxyShopOrg',
    method: 'post',
    data,
  })
}

// 修改信息——保存（第二步）
export function editProxyShopComp(data) {
  return request({
    url: '/admin/comBusiComp/editProxyShopComp',
    method: 'post',
    data,
  })
}
// 修改信息——详情（第三步）
export function selectSignFunds(data) {
  return request({
    url: '/admin/comBusiSign/selectSignFunds',
    method: 'post',
    data,
  })
}
// 修改信息——保存（第三步）
export function editSignProxyShopFunds(data) {
  return request({
    url: '/admin/comBusiSign/editSignProxyShopFunds',
    method: 'post',
    data,
  })
}
// 代销门店列表-数据信息-统计
export function selectTotalShopOrgComp(data) {
  return request({
    url: '/admin/comBusiComp/selectTotalShopOrgComp',
    method: 'post',
    data,
  })
}
// 代销门店列表-数据信息-企业列表
export function selectShopOrgCompPage(data) {
  return request({
    url: '/admin/comBusiComp/selectShopOrgCompPage',
    method: 'post',
    data,
  })
}
