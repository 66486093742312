<template>
  <div style="height: 79vh">
    <el-row>
      <el-col :span="5">
        <div style="height: 200px; padding: 10px">
          <h3 style="color: rgb(0, 130, 255)">用户详情</h3>
          <div style="display: flex; align-items: center; background: #eee; border-radius: 20px">
            <div style="margin: 0 10px">
              <el-avatar icon="el-icon-user-solid" :size="50" :src="userDetail.avatar" />
            </div>
            <div>
              <h3>{{ userDetail.nickname }}</h3>
              <h3>ID: {{ userDetail.id }}</h3>
            </div>
          </div>
          <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px; margin-top: 10px; background-color: #eee; border-radius: 12px">
            <div>
              <span>上次登录时间：</span>
              <span>{{ userDetail.lastLoginTime }}</span>
            </div>
            <div class="switch-container">
              <el-switch active-color="#13ce66" :active-value="1" :inactive-value="0" :value="userDetail.isStatus" @click.native="handleChangeStatus" />
            </div>
          </div>
          <div class="msgContainer" style="padding: 20px 0 0 0; margin-top: 20px; background-color: #eee; border-radius: 15px">
            <ul>
              <li>
                <span>用户姓名：</span>
                <span>{{ userDetail.nickname }}</span>
              </li>
              <li>
                <span>身份证号码：</span>
                <span>{{ userDetail.cardId }}</span>
              </li>
              <li>
                <span>用户生日：</span>
                <span>{{ userDetail.birthday }}</span>
              </li>
              <li>
                <span>手机号码：</span>
                <span>{{ userDetail.phone }}</span>
              </li>
              <li>
                <span>邮箱：</span>
                <span>{{ userDetail.email }}</span>
              </li>
              <li>
                <span>用户来源：</span>
                <span>{{ userDetail.userSource | getSource }}</span>
              </li>
              <li>
                <span>推荐ID：</span>
                <span>{{ userDetail.parentId }}</span>
              </li>
              <li>
                <span>注册时间：</span>
                <span>{{ userDetail.registerTime }}</span>
              </li>
              <li>
                <div style="display: flex; flex-direction: column; align-items: center; width: 200px">
                  <div ref="qrCodeUrl" class="qrcode"></div>
                  <el-button size="mini" style="margin-top: 10px; color: #fff" type="primary" @click="downloadQR">下载二维码</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col :span="19">
        <div style="margin-top: 50px">
          <el-tabs v-model="activeName" :stretch="true" @tab-click="tabClick">
            <el-tab-pane label="积分" name="1">
              <integral ref="integral" />
            </el-tab-pane>
            <el-tab-pane label="金豆" name="2">
              <goldenBean ref="goldenBean" />
            </el-tab-pane>
            <el-tab-pane label="余额" name="3">
              <balance ref="balance" />
            </el-tab-pane>
            <el-tab-pane label="收益" name="4">
              <profit ref="profit" />
            </el-tab-pane>
            <el-tab-pane label="优惠券" name="5">
              <coupon ref="coupon" />
            </el-tab-pane>
            <el-tab-pane label="收货地址" name="6">
              <receiving ref="receiving" />
            </el-tab-pane>
            <el-tab-pane label="订单" name="7">
              <order ref="order" />
            </el-tab-pane>
            <el-tab-pane label="发票" name="8">
              <invoice ref="invoice" />
            </el-tab-pane>
            <el-tab-pane label="扩展团队" name="9">
              <equipa ref="equipa" />
            </el-tab-pane>
            <el-tab-pane label="推荐团队" name="10">
              <recommended ref="recommended" />
            </el-tab-pane>
            <el-tab-pane label="收藏" name="11">
              <collect ref="collect" />
            </el-tab-pane>
            <el-tab-pane label="足迹" name="12">
              <browse ref="browse" />
            </el-tab-pane>
            <el-tab-pane label="搜索" name="13">
              <query ref="query" />
            </el-tab-pane>
            <el-tab-pane label="机构列表" name="14">
              <shop-positions ref="shopPositions" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getDetail, disableUser, recoveryUser } from '@/api/mall/distUsers/userDetail'
  import QRCode from 'qrcodejs2'
  const requireComponent = require.context('./components', true, /\.vue$/)
  const componentsObj = {}
  requireComponent.keys().forEach((filename) => {
    let name = filename.replace(/(\.\/)|\.vue$/g, '')
    const componentConfig = requireComponent(filename)
    componentsObj[name] = componentConfig.default || componentConfig
  })
  export default {
    components: componentsObj,
    filters: {
      getSource(val) {
        let str = ''
        switch (val) {
          case 'ios':
            str = '苹果IOS'
            break
          case 'android':
            str = '安卓'
            break
          case 'wxapplet':
            str = '微信小程序'
            break
          case 'pc':
            str = '电脑端'
            break
          case 'h5':
            str = '微信公众号H5'
            break
        }
        return str
      },
    },
    provide() {
      return {
        userDetail: {
          id: this.userId,
        },
      }
    },
    data() {
      return {
        activeName: '1',
        userId: 0,
        userDetail: {},
        tabs: ['integral', 'goldenBean', 'balance', 'profit', 'coupon', 'receiving', 'order', 'invoice', 'equipa', 'recommended', 'collect', 'browse', 'query', 'shopPositions'],
      }
    },
    created() {
      this.userId = this.$route.query.id
      this.fetchDetailData()
    },
    methods: {
      creatQrCode() {
        this.$refs.qrCodeUrl.innerHTML = '' //清除二维码方法一
        let text = this.userDetail.qrCodeQuery
        new QRCode(this.$refs.qrCodeUrl, {
          text: text, //页面地址 ,如果页面需要参数传递请注意哈希模式#
          width: 100,
          height: 100,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
        // qrcode.clear() // 清除二维码方法二
      },
      downloadQR() {
        const img = document.querySelector('.qrcode img')
        const src = img.getAttribute('src')
        const a = document.createElement('a')
        a.setAttribute('href', src)
        a.setAttribute('download', 'qrcode.png')
        a.click()
        console.log(img)
      },
      handleChangeStatus() {
        if (this.userDetail.isStatus == 1) {
          this.$confirm('禁用该用户加入黑名单后，用户将强制退出无法登录，您确定要将用户加入黑名单吗？', '提示！', {
            confirmButtonText: '禁用',
            type: 'warning',
          }).then(() => {
            disableUser({
              userId: this.userId,
            }).then(() => {
              this.$message.success('禁用成功')
              this.$router.push({
                path: '/user/blacklist',
              })
            })
          })
        } else {
          this.$confirm('是否恢复该用户？', '提示！', {
            confirmButtonText: '恢复',
            type: 'success',
          }).then(() => {
            recoveryUser({
              userId: this.userId,
            }).then(() => {
              this.$message.success('恢复成功！')
              this.userDetail.isStatus = 1
            })
          })
        }
      },
      tabClick(e) {
        const index = e.index
        this.$refs[this.tabs[index]].fetchData(this.userId)
      },
      async fetchDetailData() {
        if (this.userId) {
          const { data: userDetail } = await getDetail({ id: this.userId })
          this.userDetail = userDetail
          this.$nextTick(() => {
            this.creatQrCode()
          })
        }
      },
    },
  }
</script>
<style>
  .msgContainer li {
    padding: 12px 0;
    list-style: none;
  }
  .msgContainer li span:nth-child(1) {
    color: #bbb;
  }
  .switch-container .el-switch__core::after {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 100%;
    color: red;
    text-align: center;
    content: '禁';
  }
  .qrcode {
    display: inline-block;
  }
  .qrcode img {
    box-sizing: border-box;
    width: 132px;
    height: 132px;
    padding: 6px;
    background-color: #fff;
  }
</style>
