import request from '@/utils/request'
// 员工列表
export function queryPage(data) {
  return request({
    url: `/user/comIncUserBase/queryPage`,
    method: 'post',
    data,
  })
}
// 新增员工（员工信息第一步）
export function addUserArchive(data) {
  return request({
    url: `/user/comIncUserArchive/add`,
    method: 'post',
    data,
  })
}
// 新增员工（教育信息第二步）
export function addUserTech(data) {
  return request({
    url: `/user/comIncUserTech/add`,
    method: 'post',
    data,
  })
}
// 新增员工（银行卡信息第三步）
export function addUserCard(data) {
  return request({
    url: `/user/comIncUserCard/add`,
    method: 'post',
    data,
  })
}
// 新增员工（合同信息第四步）
export function addUserSign(data) {
  return request({
    url: `/user/comIncUserSign/add`,
    method: 'post',
    data,
  })
}
// 员工详情
export function detailsUser(data) {
  return request({
    url: `/user/comIncUserArchive/details`,
    method: 'post',
    data,
  })
}
// 员工转正
export function positive(data) {
  return request({
    url: `/user/comIncUserArchive/positive`,
    method: 'post',
    data,
  })
}
// 编辑部门
export function updatePost(data) {
  return request({
    url: `/user/comIncUserArchive/updatePost`,
    method: 'post',
    data,
  })
}
// 离职
export function quit(data) {
  return request({
    url: `/user/comIncUserArchive/quit`,
    method: 'post',
    data,
  })
}
// 设置部门
export function addPosts(data) {
  return request({
    url: `/user/comIncUserPost/add`,
    method: 'post',
    data,
  })
}
// 机构IDList查询机构对应的职位信息
export function selectOrgPostList(data) {
  return request({
    url: `/upms/admin/comSysOrgPost/selectOrgPostList`,
    method: 'post',
    data,
  })
}
// 修改员工类型

export function updateInnerLevel(data) {
  return request({
    url: `/upms/user/uUserOrgRelation/updateInnerLevel`,
    method: 'post',
    data,
  })
}
// 上传体检报告
export function addSysPortFile(data) {
  return request({
    url: `/upms/user/comIncUserArchive/addSysPortFile`,
    method: 'post',
    data,
  })
}
// 验证手机号
export function checkPhone(data) {
  return request({
    url: `/upms/user/comIncUserArchive/checkPhone`,
    method: 'post',
    data,
  })
}
// 设置讲师
export function setJsLvl(data) {
  return request({
    url: `/upms/user/uUserOrgRelation/setJsLvl`,
    method: 'post',
    data,
  })
}
