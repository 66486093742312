var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "common-form",
        {
          ref: "commonForm",
          attrs: { options: _vm.searchOptions },
          model: {
            value: _vm.searchForm,
            callback: function ($$v) {
              _vm.searchForm = $$v
            },
            expression: "searchForm",
          },
        },
        [
          _c(
            "template",
            { slot: "operation" },
            [
              _c("el-button", { attrs: { type: "primary" } }, [_vm._v("搜索")]),
              _c("el-button", [_vm._v("重置")]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "m-b" },
        [_c("el-button", { attrs: { type: "primary" } }, [_vm._v("批量操作")])],
        1
      ),
      _c(
        "el-table",
        { attrs: { border: "", data: _vm.data } },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "编码",
              prop: "userCode",
              "show-overflow-tooltip": "",
              width: "70",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "头像", prop: "headimgUrl" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-image", {
                      attrs: {
                        lazy: true,
                        "preview-src-list": [row.headimgUrl],
                        src: row.headimgUrl,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "昵称",
              prop: "nickName",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "手机号",
              prop: "phone",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "性别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.sex == 2
                      ? _c("span", [_vm._v("女")])
                      : row.sex == 1
                      ? _c("span", [_vm._v("男")])
                      : row.sex == 0
                      ? _c("span", [_vm._v("保密")])
                      : _c("span", [_vm._v(_vm._s(row.sex))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "所在城市",
              prop: "city",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "所属员工",
              prop: "city",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.show(row)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "default", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "medium", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.appoint(row)
                          },
                        },
                      },
                      [_vm._v(" 指定员工 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "指定员工",
            visible: _vm.appointDialog,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.appointDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex-center" },
            [
              _c("el-transfer", {
                attrs: {
                  data: _vm.appointData,
                  filterable: "",
                  props: {
                    key: "id",
                    label: "username",
                  },
                  titles: ["员工列表", "指定员工"],
                },
                on: {
                  change: _vm.teaChange,
                  "left-check-change": _vm.leftChange,
                  "right-check-change": _vm.rightChange,
                },
                model: {
                  value: _vm.appointUser,
                  callback: function ($$v) {
                    _vm.appointUser = $$v
                  },
                  expression: "appointUser",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.appointDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("table-edit", {
        ref: "edit",
        attrs: { config: _vm.config, options: _vm.options, rules: _vm.rules },
        on: { fileStatus: _vm.fileupload, submit: _vm.submit },
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }