const options = [
  {
    label: '规格信息',
    span: 24,
    prop: 'ggxx',
    type: 'radio',
    optionData: [
      { label: '统一规格', value: 1 },
      { label: '多规格', value: 2 },
    ],
  },
  {
    span: 24,
    prop: 'tygg',
    slot: true,
  },
]

const rules = {
  c: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}
const config = { labelWidth: '8em' }
const oddOptions = [
  { label: '销售价(元)', type: 'number', span: 13, prop: 'q1' },
  { label: '市场价(元)', type: 'number', span: 13, prop: 'q2' },
  { label: '成本价(元)', type: 'number', span: 13, prop: 'q3' },
  { label: '库存', type: 'number', span: 13, prop: 'q4' },
  { label: '重量(kg)', type: 'number', span: 13, prop: 'q5' },
  { label: '体积(m³)', type: 'number', span: 13, prop: 'q6' },
  // { label: '一级返佣', type: 'number', span: 13, prop: 'q7' },
  // { label: '二级返佣', type: 'number', span: 13, prop: 'q8' },
]
export { options as normsOptions, rules, config, oddOptions }
