export default {
  swapArray(arr, index1, index2) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    return arr
  },
  moveUp(arr, index) {
    this.swapArray(arr, index, index - 1)
  },
  moveDown(arr, index) {
    this.swapArray(arr, index, index + 1)
  },
}
