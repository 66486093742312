const options = [
  { label: '昵称', span: 12, prop: 'nickName' },
  { label: '账号', span: 12, prop: 'username' },
  { label: '密码', span: 12, prop: 'password' },
  {
    label: '店铺',
    span: 12,
    prop: 'shopId',
    type: 'select',
    key: 'name',
    value: 'id',
    url: '/mall/shopinfo/list',
    optionData: [],
  },
  { label: '手机号', span: 12, prop: 'phone' },
  { label: '邮箱', span: 12, prop: 'email' },
  { label: '头像', span: 24, prop: 'avatar', type: 'upload' },
]

const rules = {
  a: [{ required: true, trigger: 'blur', message: '请输入标题' }],
  b: [{ required: true, trigger: 'blur', message: '请输入作者' }],
}

const searchOptions = [
  {
    label: '风险池',
    type: 'select',
    prop: 'shopId',
    span: 6,
    labelWidth: '4em',
    key: 'name',
    value: 'id',
    url: '/mall/shopinfo/list',
    optionData: [],
  },
  {
    label: '风险池',
    prop: 'username',
    span: 6,
    labelWidth: '4em',
  },
  { type: 'operation', span: 6 },
]
const config = {}
export { options, rules, config, searchOptions }
