var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "div",
        { staticClass: "m-b" },
        [
          _vm._l(_vm.dynamicTags, function (tag) {
            return _c(
              "el-tag",
              {
                key: tag,
                attrs: {
                  closable: "",
                  "disable-transitions": false,
                  size: "medium",
                },
                on: {
                  close: function ($event) {
                    return _vm.handleClose(tag)
                  },
                },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          _vm.inputVisible
            ? _c("el-input", {
                ref: "saveTagInput",
                staticClass: "input-new-tag",
                attrs: { size: "mini" },
                on: { blur: _vm.handleInputConfirm },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleInputConfirm.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              })
            : _c(
                "el-button",
                {
                  staticClass: "m-l",
                  attrs: { size: "mini" },
                  on: { click: _vm.showInput },
                },
                [_vm._v(" + New Tag ")]
              ),
        ],
        2
      ),
      _c(
        "el-table",
        { attrs: { data: [] } },
        [
          _vm._l(_vm.header, function (items) {
            return [
              _c("el-table-column", {
                key: items.prop,
                attrs: { label: items.label, prop: items.prop },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }