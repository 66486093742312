import request from '@/utils/request'

export function getGoodsList(data) {
  return request({
    url: `/goods/proGoodsBasics/queryPage`,
    method: 'post',
    data,
  })
}

export function updateListOffSales(data) {
  return request({
    url: `/goods/proGoodsBasics/updateListOffSales`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

export function doDelete(data) {
  return request({
    url: `/goods/proGoodsBasics/deleteById`,
    method: 'post',
    data,
  })
}

export function updateListDelete(data) {
  return request({
    url: `/goods/proGoodsBasics/updateListDelete`,
    method: 'post',
    data,
  })
}

export function exportData(data) {
  return request({
    url: `/goods/proGoodsBasics/export`,
    method: 'post',
    data,
  })
}

export function updateIsShow(data) {
  return request({
    url: `/goods/proGoodsBasics/updateIsShow`,
    method: 'post',
    data,
  })
}

export function updateOnSales(data) {
  return request({
    url: `/goods/proGoodsBasics/updateOnSales`,
    method: 'post',
    data,
  })
}

export function updateOffSales(data) {
  return request({
    url: `/goods/proGoodsBasics/updateOffSales`,
    method: 'post',
    data,
  })
}

export function getSpecs(data) {
  return request({
    url: `/goods/proGoodsBasicsSpecs/goodsSpecs`,
    method: 'post',
    data,
  })
}

export function updateListOnSales(data) {
  return request({
    url: `/goods/proGoodsBasics/updateListOnSales`,
    method: 'post',
    data,
  })
}

export function updateListBackStore(data) {
  return request({
    url: `/goods/proGoodsBasics/updateListBackStore`,
    method: 'post',
    data,
  })
}

export function updateBackStore(data) {
  return request({
    url: `/goods/proGoodsBasics/updateBackStore`,
    method: 'post',
    data,
  })
}

export function resetStock(data) {
  return request({
    url: `/goods/proGoodsBasicsSpecs/resetStock`,
    method: 'post',
    data,
  })
}

export function downloadFile(data) {
  return request({
    url: `/goods/proGoodsBasics/export`,
    responseType: 'blob',
    method: 'post',
    data,
  })
}

export function getGoodsSpecs(data) {
  return request({
    url: `/goods/proGoodsBasicsSpecs/goodsSpecs`,
    method: 'post',
    data,
  })
}

export function getGoodsCount(data) {
  return request({
    url: `/goods/proGoodsBasics/selectGoodsCount`,
    method: 'post',
    data,
  })
}

export function getWarmPage(data) {
  return request({
    url: `/goods/proGoodsBasics/queryWarmPage`,
    method: 'post',
    data,
  })
}
