var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: "新增就医记录",
        visible: _vm.dialogVisible,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                "label-width": "100px",
                model: _vm.ruleForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "div",
                { staticClass: "demo-ruleForms" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "就医单位", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入 就医单位" },
                        model: {
                          value: _vm.ruleForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "title", $$v)
                          },
                          expression: "ruleForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "就诊科室", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入 就诊科室" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "就医类型", prop: "fitType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择 就医类型" },
                          model: {
                            value: _vm.ruleForm.fitType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "fitType", $$v)
                            },
                            expression: "ruleForm.fitType",
                          },
                        },
                        _vm._l(_vm.checknum, function (ym, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: ym.labelName, value: ym.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "就医卡号", prop: "fitNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入 就医卡号" },
                        model: {
                          value: _vm.ruleForm.fitNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "fitNum", $$v)
                          },
                          expression: "ruleForm.fitNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "就医时间", prop: "phyTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "center",
                          placeholder: "选择就医时间",
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.ruleForm.phyTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phyTime", $$v)
                          },
                          expression: "ruleForm.phyTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出院时间", prop: "leaveTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "center",
                          placeholder: "选择出院时间",
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.ruleForm.leaveTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "leaveTime", $$v)
                          },
                          expression: "ruleForm.leaveTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主管医生", prop: "medicName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入 主管医生" },
                        model: {
                          value: _vm.ruleForm.medicName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "medicName", $$v)
                          },
                          expression: "ruleForm.medicName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "当前状态", prop: "fitEnable" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择 当前状态" },
                          model: {
                            value: _vm.ruleForm.fitEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "fitEnable", $$v)
                            },
                            expression: "ruleForm.fitEnable",
                          },
                        },
                        _vm._l(_vm.labType, function (ym, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: ym.labelName, value: ym.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "主诉", prop: "chiefTitle" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入 主诉" },
                    model: {
                      value: _vm.ruleForm.chiefTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "chiefTitle", $$v)
                      },
                      expression: "ruleForm.chiefTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "检查", prop: "mark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入 检查" },
                    model: {
                      value: _vm.ruleForm.mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "mark", $$v)
                      },
                      expression: "ruleForm.mark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "诊断", prop: "diagnosis" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入 诊断" },
                    model: {
                      value: _vm.ruleForm.diagnosis,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "diagnosis", $$v)
                      },
                      expression: "ruleForm.diagnosis",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "治疗方案", prop: "precept" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入 治疗方案" },
                    model: {
                      value: _vm.ruleForm.precept,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "precept", $$v)
                      },
                      expression: "ruleForm.precept",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }