<template>
  <div v-loading.sync="listLoading" style="padding: 10px">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div>
        <div style="display: flex">
          <h3 style="margin-right: 10px">当前发票</h3>
          <h3 style="color: rgb(37, 115, 217)">{{ detail.totalInvoiceNum }}</h3>
        </div>
        <div style="display: flex; margin: 10px 0">
          <div style="margin-right: 20px">
            <span>已开票：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.invoicedNum }}
            </span>
          </div>
          <div style="margin-right: 20px">
            <span>未开票：</span>
            <span style="color: rgb(37, 115, 217)">
              {{ detail.notInvoicedNum }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="
            btnRolrs[8] &&
            btnRolrs[8]['searchBarButtons'] &&
            btnRolrs[8]['searchBarButtons'].length
          "
        >
          <div v-if="btnRolrs[8] && btnRolrs[8].searchBarButtons">
            <el-button
              v-for="item in btnRolrs[8].searchBarButtons"
              :key="item.id"
              :icon="item.buttonIcon"
              size="small"
              :type="item.buttonCss"
              @click="handleClickBtn(item.buttonUrl)"
            >
              <vab-icon :icon="item.buttonIcon || ''" />
              {{ item.buttonName }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="display: flex; flex-wrap: wrap; margin-left: -20px">
        <div class="title-item">
          <span>订单编号</span>
          <el-input
            v-model="formInline.orderCode"
            class="title-input"
            placeholder="请输入订单编号"
          />
        </div>
        <div class="title-item">
          <span>开票状态</span>
          <el-select
            v-model="formInline.invoicingState"
            class="title-input"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in invoicingState"
              :key="index"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
        </div>
        <div class="title-item">
          <el-button icon="el-icon-search" type="warning" @click="handlesearch">
            搜索
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            type="primary"
            @click="handleReset"
          >
            搜索
          </el-button>
        </div>
      </div>
    </div>
    <div>
      <el-table border :data="list" max-height="450" style="width: 100%">
        <el-table-column align="center" label="订单编号" prop="orderCode" />
        <el-table-column
          align="center"
          label="订单总额"
          prop="originalAmount"
        />
        <el-table-column align="center" label="发票金额" prop="paidAmount" />
        <el-table-column align="center" label="发票类型" prop="textureType">
          <template #default="{ row }">
            <div>{{ row.textureType == 1 ? '电子发票' : '纸质发票' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发票抬头" prop="invoiceTitle" />
        <el-table-column
          align="center"
          label="发票税率（%）"
          prop="invoiceFeeRate"
        />
        <el-table-column align="center" label="开票状态">
          <template #default="{ row }">
            <div>{{ row.invoicingState == 1 ? '已开票' : '未开票' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请时间" prop="createTime" />
        <el-table-column align="center" label="操作">
          <template #default="{ row }">
            <el-button v-if="row.invoicingState == 1" type="primary">
              查看
            </el-button>
            <el-button v-else type="danger">开票</el-button>
            <el-button type="text">订单详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.current"
        :layout="layout"
        :page-size="queryForm.size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import {
    getOrderInvoice,
    getOrderInvoiceDetail,
  } from '@/api/mall/distUsers/userDetail'
  import { selectSysDict } from '@/api/mall/common'
  import { checkButton } from '@/utils/mixins'
  export default {
    mixins: [checkButton],
    data() {
      return {
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        userId: 0,
        balanceAmount: 0,
        isSearch: false,
        queryForm: {
          current: 1,
          size: 10,
        },
        total: 0,
        accId: '',
        list: [],
        invoicingState: [],
        formInline: {
          orderCode: '',
          invoicingState: '',
        },
        detail: {
          invoicedNum: 0,
          notInvoicedNum: 0,
          totalInvoiceNum: 0,
        },
      }
    },
    created() {
      const { balanceAmount, accId } = this.$route.query
      this.balanceAmount = balanceAmount
      this.accId = accId
    },
    methods: {
      handleReset() {
        this.formInline = {
          orderCode: '',
          invoicingState: '',
        }
        this.handlesearch()
      },
      async fetchData(id, options) {
        this.listLoading = true
        this.userId = id
        const {
          data: { records, total },
        } = await getOrderInvoice({
          userId: this.userId,
          ...this.queryForm,
          ...options,
        })
        const invoiceStatus = await selectSysDict({
          dictTypeList: ['invoiceStatus'],
        })
        this.invoicingState = invoiceStatus.data[0].dictInfoVoList
        const { data: detail } = await getOrderInvoiceDetail({
          userId: this.userId,
        })
        this.detail = detail
        this.total = total
        this.list = records
        this.listLoading = false
      },
      async handlesearch() {
        this.queryForm.current = 1
        await this.fetchData(this.userId, { ...this.formInline })
        let temp = false
        Object.keys(this.formInline).forEach((key) => {
          this.formInline[key] && (temp = true)
        })
        this.isSearch = temp
      },
      handleSizeChange(val) {
        this.queryForm.size = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData(this.userId, { ...data })
      },
      handleCurrentChange(val) {
        this.queryForm.current = val
        let data = this.isSearch === true ? this.formInline : {}
        this.fetchData(this.userId, { ...data })
      },
      handleExport() {
        // exportIntegral({
        //   accId: this.accId,
        //   userId: this.userId,
        // })
        //   .then((res) => {
        //     const url = window.URL.createObjectURL(new Blob([res]))
        //     const link = document.createElement('a')
        //     link.setAttribute('href', url)
        //     link.setAttribute('download', new Date().getTime() + '.xlsx')
        //     link.click()
        //   })
        //   .catch(() => {
        //     this.$message.error('网络错误，请稍后重试！')
        //   })
      },
    },
  }
</script>

<style scoped>
  .title-input {
    width: 150px;
    margin-left: 5px;
  }
  .title-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  .title-item span {
    width: 80px;
    text-align: center;
  }
</style>
